import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import { useGlobalStore } from '../GlobalStore.context';
import { TRACE_ENTITY } from 'app-entities/trace';
import { checkNonNullInArray, getClientTimeZoneUTC } from 'utils';
import { Localization, useNotification } from 'connex-cds';
const { useTranslateMessage } = Localization;

export const FILTERS_OPTIONS = {
  traceLevel: {
    options: [
      {
        value: 'All',
        translation: {
          id: 'traceLevelFilter_all',
          onSelectedId: 'traceLevelFilter',
        },
      },
      {
        value: 'Error',
        translation: 'traceLevelFilter_error',
      },
    ],
  },
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  dataByFilters: [],
  dataByFiltersAndSearch: [],
  error: '',
  dataUpdatedAt: null,
  dateTypeFilter: 'Past_5_minutes',
  dateRangeFilter: [null, null],
  minorFilters: {
    traceLevel: 'All',
    source: null,
    sourceType: null,
    traceType: null,
    objectId: null,
    objectType: null,
    eventDetails: null,
  },
  traceLevelFilterValue: FILTERS_OPTIONS.traceLevel.options[0].value,
  isEmptyByResponse: false,
  isEmptyByFiltersAndSearch: false,
  initialize: () => {},
  onChangeDateTypeFilter: () => {},
  onChangeDateRangeFilter: () => {},
  onChangeMinorFilters: () => {},
  onChangeTraceLevelFilter: () => {},
  onChangeActiveFilters: () => {},
  onChangeActiveColumns: () => {},
  replaceTrace: () => {},
  fetchByDateRange: () => {},
  onRefreshData: () => {},
  onSourceChange: () => {},
  onSourceTypeChange: () => {},
  onTraceTypeChange: () => {},
  onObjectIdChange: () => {},
  onObjectTypeChange: () => {},
};

export const TRACES_SEARCHABLE_FIELDS = {
  dexaId: { translation: 'autoComplete_label_dexa', value: ' #', icon: 'order' },
  typeId: { translation: 'autoComplete_label_type', value: ' #', icon: 'ticket' },
  sourceSystemId: { translation: 'autoComplete_label_sourceSystem', value: ' #', icon: 'order' },
  sourceSystemTypeId: { translation: 'autoComplete_label_sourceSystemType', value: ' #', icon: 'ticket' },
  objectId: { translation: 'autoComplete_label_object', value: ' #', icon: 'order' },
  traceTypeId: { translation: 'autoComplete_label_traceType', value: ' #', icon: 'ticket' },
};

const rowIsMatch = (row, fieldName, searchTerm) => {
  return row?.[fieldName] && row?.[fieldName].toLowerCase().includes(searchTerm.toLowerCase());
};

const FILTER_FNS = {
  'traceLevel-All': prevData => prevData,
  'traceLevel-Error': prevData => prevData.filter(row => row.traceLevelId === 'ERROR'),
  source: (prevData, source) => prevData.filter(row => rowIsMatch(row, 'sourceSystemId', source)),
  sourceType: (prevData, sourceType) => prevData.filter(row => rowIsMatch(row, 'sourceSystemTypeId', sourceType)),
  traceType: (prevData, traceType) => prevData.filter(row => rowIsMatch(row, 'traceTypeId', traceType)),
  objectId: (prevData, objectId) => prevData.filter(row => rowIsMatch(row, 'objectId', objectId)),
  objectType: (prevData, objectType) => prevData.filter(row => rowIsMatch(row, 'objectTypeId', objectType)),
  eventDetails: (prevData, eventDetails) =>
    prevData.filter(row => {
      if (!row?.events) {
        return false;
      }
      return JSON.stringify(row.events).toLowerCase().includes(eventDetails.toLowerCase());
    }),
};

const TracesStoreContext = createContext(INITIAL_STATE);

const TracesStoreProvider = ({ children }) => {
  const notification = useNotification();
  const translateMessage = useTranslateMessage();

  const { searchTerm, onChangeSearchTerm, onChangeUserSettings } = useGlobalStore();
  const [data, setData] = useState(INITIAL_STATE.data);
  const [dateTypeFilter, setDateTypeFilter] = useState(INITIAL_STATE.dateTypeFilter);
  const [dateRangeFilter, setDateRangeFilter] = useState(INITIAL_STATE.dateRangeFilter);
  const [minorFilters, setMinorFilters] = useState(INITIAL_STATE.minorFilters);
  const [traceLevelFilterValue, setTraceLevelFilterValue] = useState(INITIAL_STATE.traceLevelFilterValue);
  // const [isLoading, setIsLoading] = useState(INITIAL_STATE.isLoading);
  // const [error, setError] = useState(INITIAL_STATE.error);
  // const [dataUpdatedAt, setDataUpdatedAt] = useState(INITIAL_STATE.dataUpdatedAt);
  const [source, setSource] = useState(INITIAL_STATE.source);
  const [sourceType, setSourceType] = useState(INITIAL_STATE.sourceType);
  const [traceType, setTraceType] = useState(INITIAL_STATE.traceType);
  const [objectId, setObjectId] = useState(INITIAL_STATE.objectId);
  const [objectType, setObjectType] = useState(INITIAL_STATE.objectType);
  const [eventDetails, setEventDetails] = useState(INITIAL_STATE.eventDetails);

  const { isLoading, error, mutateAsync } = TRACE_ENTITY.appLogic().List();

  const traceRef = useRef('');

  const isEmptyByResponse = useMemo(() => data?.length === 0, [data]);

  const dataByFilters = useMemo(() => {
    if (!data || isEmptyByResponse) {
      return [];
    }

    let newData = [...data];
    const traceLevelFilter = FILTER_FNS[`traceLevel-${minorFilters.traceLevel}`];
    newData = traceLevelFilter(newData);
    if (minorFilters.source) {
      newData = FILTER_FNS.source(newData, minorFilters.source);
    }
    if (minorFilters.sourceType) {
      newData = FILTER_FNS.sourceType(newData, minorFilters.sourceType);
    }
    if (minorFilters.traceType) {
      newData = FILTER_FNS.traceType(newData, minorFilters.traceType);
    }
    if (minorFilters.objectId) {
      newData = FILTER_FNS.objectId(newData, minorFilters.objectId);
    }
    if (minorFilters.objectType) {
      newData = FILTER_FNS.objectType(newData, minorFilters.objectType);
    }
    if (minorFilters.eventDetails) {
      newData = FILTER_FNS.eventDetails(newData, minorFilters.eventDetails);
    }

    return newData;
  }, [data, minorFilters, isEmptyByResponse]);

  const dataByFiltersAndSearch = useMemo(() => {
    let newData = [...dataByFilters];

    if (searchTerm) {
      newData = newData.filter(item => {
        return Object.keys(item).some(key => {
          if (Object.keys(TRACES_SEARCHABLE_FIELDS).includes(key)) {
            const value = item[key] ?? '';
            return value.toLowerCase().includes(searchTerm.toLowerCase());
          }
          return false;
        });
      });
    }

    return newData;
  }, [searchTerm, dataByFilters]);

  const isEmptyByFiltersAndSearch = useMemo(() => dataByFiltersAndSearch?.length === 0, [dataByFiltersAndSearch]);

  const fetchData = useCallback(
    async queryParams => {
      if (!traceRef.current) {
        return;
      }
      if (queryParams.dateType && queryParams.dateType !== 'Custom') {
        let startDate = new Date();
        let endDate = new Date();
        switch (queryParams.dateType) {
          case 'Today':
            startDate.setHours(0, 0, 0, 0);
            break;
          case 'Yesterday':
            startDate.setDate(startDate.getDate() - 1);
            startDate.setHours(0, 0, 0, 0);
            endDate.setDate(endDate.getDate() - 1);
            endDate.setHours(23, 59, 59, 999);
            break;
          case 'Past_5_minutes':
            startDate.setMinutes(startDate.getMinutes() - 5);
            break;
          case 'Past_15_minutes':
            startDate.setMinutes(startDate.getMinutes() - 15);
            break;
          case 'Past_30_minutes':
            startDate.setMinutes(startDate.getMinutes() - 30);
            break;
          case 'Past_hour':
            startDate.setHours(startDate.getHours() - 1);
            break;
          case 'Past_3_hours':
            startDate.setHours(startDate.getHours() - 3);
            break;
          case 'Past_6_hours':
            startDate.setHours(startDate.getHours() - 6);
            break;
          default:
            break;
        }
        queryParams.startDate = startDate.toISOString();
        queryParams.endDate = endDate.toISOString();
      }
      // const result = DEXA_ENTITY.appLogic().ListTraces();
      // setData(result.data);
      // setError(result.error);
      // setIsLoading(result.isLoading);
      // setDataUpdatedAt(result.dataUpdatedAt);
      const result = await mutateAsync({ entityRef: traceRef.current, queryParams });
      setData(result);
    },
    [mutateAsync]
  );

  const handleSourceChange = event => {
    setSource(event?.target?.value);
    handleChangeMinorFilters(prevFilters => ({
      ...prevFilters,
      source: event?.target?.value,
    }));
  };

  const handleSourceTypeChange = event => {
    setSourceType(event?.target?.value);
    handleChangeMinorFilters(prevFilters => ({
      ...prevFilters,
      sourceType: event?.target?.value,
    }));
  };

  const handleTraceTypeChange = event => {
    setTraceType(event?.target?.value);
    handleChangeMinorFilters(prevFilters => ({
      ...prevFilters,
      traceType: event?.target?.value,
    }));
  };

  const handleObjectIdChange = event => {
    setObjectId(event?.target?.value);
    handleChangeMinorFilters(prevFilters => ({
      ...prevFilters,
      objectId: event?.target?.value,
    }));
  };

  const handleObjectTypeChange = event => {
    setObjectType(event?.target?.value);
    handleChangeMinorFilters(prevFilters => ({
      ...prevFilters,
      objectType: event?.target?.value,
    }));
  };

  const handleEventDetailsChange = event => {
    setEventDetails(event?.target?.value);
    handleChangeMinorFilters(prevFilters => ({
      ...prevFilters,
      eventDetails: event?.target?.value,
    }));
  };

  const handleSearch = () => {
    let startDate, endDate;
    if (dateTypeFilter === 'Custom') {
      ({ startDate, endDate } = getStartAndEndDateFromRange());
    }
    fetchData({
      sourceSystemId: source,
      sourceSystemTypeId: sourceType,
      objectId: objectId,
      traceTypeId: traceType,
      typeId: objectType,
      dateType: dateTypeFilter,
      startDate,
      endDate,
    });
  };

  const handleChangeDateTypeFilter = newValue => {
    setDateTypeFilter(newValue);
    setDateRangeFilter(INITIAL_STATE.dateRangeFilter);

    if (newValue !== 'Custom') {
      fetchData({
        sourceSystemId: source,
        sourceSystemTypeId: sourceType,
        objectId: objectId,
        traceTypeId: traceType,
        typeId: objectType,
        dateType: newValue,
      });
    } else {
      // setData(INITIAL_STATE.data);
    }
  };

  const getStartAndEndDateFromRange = useCallback(() => {
    if (dateRangeFilter.length > 0 && checkNonNullInArray(dateRangeFilter)) {
      const startDate = dateRangeFilter[0].$d.toISOString();

      const endDate = dateRangeFilter[1].$d.toISOString();

      // Check to see if the date range is valid
      if (new Date(startDate) > new Date(endDate)) {
        notification.error(translateMessage('start_date_after_end_date_error'));
        return {};
      }

      // Check to see if the date range exceeds 31 days
      const diffInDays = Math.ceil((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24));
      if (diffInDays > 31) {
        notification.error(translateMessage('date_range_too_long_error'));
        return {};
      }
      return { startDate, endDate };
    }
    return {};
  }, [dateRangeFilter, notification, translateMessage]);

  const handleFetchByDateRange = useCallback(() => {
    const { startDate, endDate } = getStartAndEndDateFromRange();

    if (!startDate || !endDate) {
      return;
    }

    fetchData({
      sourceSystemId: source,
      sourceSystemTypeId: sourceType,
      objectId: objectId,
      traceTypeId: traceType,
      typeId: objectType,
      startDate,
      endDate,
    });
  }, [fetchData, getStartAndEndDateFromRange, objectId, source, sourceType, traceType, objectType]);

  const handleFetchByDateTypeFilter = useCallback(
    () =>
      dateTypeFilter === 'Custom'
        ? handleFetchByDateRange()
        : fetchData({
            sourceSystemId: source,
            sourceSystemTypeId: sourceType,
            objectId: objectId,
            traceTypeId: traceType,
            typeId: objectType,
            dateType: dateTypeFilter,
          }),
    [dateTypeFilter, fetchData, handleFetchByDateRange, objectId, source, sourceType, traceType, objectType]
  );

  const handleInitialize = useCallback(
    newEntityRef => {
      traceRef.current = newEntityRef;
      onChangeSearchTerm(searchTerm);
      handleFetchByDateTypeFilter();
    },
    [handleFetchByDateTypeFilter, searchTerm, onChangeSearchTerm]
  );

  const handleChangeDateRangeFilter = newRange => setDateRangeFilter(newRange);

  const handleChangeMinorFilters = newMinorFiltersFn => setMinorFilters(newMinorFiltersFn);

  const handleChangeTraceLevelFilter = newValue => setTraceLevelFilterValue(newValue);

  const handleActiveFilters = newValue => {
    onChangeUserSettings(prevState => {
      let newState = prevState;
      newState.trace.activeFilters = newValue;
      return newState;
    });
    setMinorFilters(prevState => prevState);
  };

  const handleActiveColumns = newValue => {
    onChangeUserSettings(prevState => {
      let newState = prevState;
      newState.trace.columnsActive = newValue;
      return newState;
    });
  };

  return (
    <TracesStoreContext.Provider
      value={{
        isLoading,
        data,
        dataByFilters,
        dataByFiltersAndSearch,
        error,
        dateTypeFilter,
        dateRangeFilter,
        minorFilters,
        traceLevelFilterValue,
        isEmptyByResponse,
        isEmptyByFiltersAndSearch,
        source,
        sourceType,
        traceType,
        objectId,
        objectType,
        eventDetails,
        onChangeDateTypeFilter: handleChangeDateTypeFilter,
        onChangeDateRangeFilter: handleChangeDateRangeFilter,
        onChangeMinorFilters: handleChangeMinorFilters,
        onChangeTraceLevelFilter: handleChangeTraceLevelFilter,
        onChangeActiveFilters: handleActiveFilters,
        onChangeActiveColumns: handleActiveColumns,
        fetchByDateRange: handleFetchByDateRange,
        initialize: handleInitialize,
        onRefreshData: handleFetchByDateTypeFilter,
        onSourceChange: handleSourceChange,
        onSourceTypeChange: handleSourceTypeChange,
        onTraceTypeChange: handleTraceTypeChange,
        onObjectIdChange: handleObjectIdChange,
        onObjectTypeChange: handleObjectTypeChange,
        onEventDetailsChange: handleEventDetailsChange,
        onSearch: handleSearch,
      }}
    >
      {children}
    </TracesStoreContext.Provider>
  );
};

const useTracesStore = () => {
  const {
    data,
    dataByFilters,
    dataByFiltersAndSearch,
    error,
    isLoading,
    dateTypeFilter,
    dateRangeFilter,
    minorFilters,
    traceLevelFilterValue,
    isEmptyByResponse,
    isEmptyByFiltersAndSearch,
    source,
    sourceType,
    traceType,
    objectType,
    objectId,
    eventDetails,
    initialize,
    replaceTrace,
    fetchByDateRange,
    onChangeDateTypeFilter,
    onChangeDateRangeFilter,
    onChangeMinorFilters,
    onChangeTraceLevelFilter,
    onChangeActiveFilters,
    onChangeActiveColumns,
    onRefreshData,
    onSourceChange,
    onSourceTypeChange,
    onTraceTypeChange,
    onObjectTypeChange,
    onObjectIdChange,
    onEventDetailsChange,
    onSearch,
  } = useContext(TracesStoreContext);

  return {
    traces: data,
    tracesByFilters: dataByFilters,
    tracesByFiltersAndSearch: dataByFiltersAndSearch,
    error,
    isLoading,
    dateTypeFilter,
    dateRangeFilter,
    minorFilters,
    traceLevelFilterValue,
    isEmptyByResponse,
    isEmptyByFiltersAndSearch,
    source,
    sourceType,
    traceType,
    objectType,
    objectId,
    eventDetails,
    initialize,
    replaceTrace,
    fetchByDateRange,
    onChangeDateTypeFilter,
    onChangeDateRangeFilter,
    onChangeMinorFilters,
    onChangeTraceLevelFilter,
    onChangeActiveFilters,
    onChangeActiveColumns,
    onRefreshData,
    onSourceChange,
    onSourceTypeChange,
    onTraceTypeChange,
    onObjectTypeChange,
    onObjectIdChange,
    onEventDetailsChange,
    onSearch,
  };
};

export { TracesStoreContext as TracesStoreContext, TracesStoreProvider as TracesStoreProvider, useTracesStore };
