import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';

export const EmptySearch = styled(Stack)(
  ({ theme }) => css`
    align-items: center;
    padding: 160px 0 30px;
    @media screen and (max-width: 1400px) {
      padding: 80px 0 30px;
    }
    overflow: auto;
    height: 100%;

    svg {
      margin-bottom: 35px;
    }

    p {
      max-width: 408px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin: 0;
      &.emptyState-highlight {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 19px;
      }
    }
  `
);
