import queryString from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { isEmpty } from 'lodash';
import App from './App';
import { cacheItem } from 'utils/cache';
import { API, getCachedItem } from 'connex-cds';

import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  '22ea14ca0a454aeb72aeb91bd13be649Tz02ODk1OCxFPTE3MTg4MDI2MTM3NjMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

try {
  const queryParams = queryString.parse(window.location.search);

  if (!isEmpty(queryParams)) {
    const previousConnexId = localStorage.getItem('x-connex-id') || '';

    const connexId = queryParams['x-connex-id'] || previousConnexId;
    const source = queryParams['source'] || localStorage.getItem('source');
    const language = queryParams['language'] || localStorage.getItem('language');
    const themeName = queryParams['theme'] || localStorage.getItem('themeName') || 'dark';
    const entityRef = queryParams['entityRef'] || localStorage.getItem('entityRef');
    const ticketRef = queryParams['crn'];
    const apiKeys = queryParams['apiKeys'] || '';

    cacheItem('source', source || '');
    cacheItem('themeName', themeName);
    cacheItem('language', language || '');
    cacheItem('entityRef', entityRef);
    cacheItem('ticketRef', ticketRef);

    const decodedApiKeys = isEmpty(apiKeys) ? '' : decodeURIComponent(apiKeys);
    cacheItem('apiKeys', decodedApiKeys);

    if (connexId) {
      cacheItem('x-connex-id', connexId);
    }

    // Strip query params off URL
    window?.history?.replaceState?.(
      {},
      null,
      `${window?.location?.pathname?.[0] !== '/' ? '/' : ''}${window.location.pathname}`
    );

    API.setSource('dexa');
    API.setApiKeys(JSON.parse(getCachedItem('apiKeys') || {}));
  }
} catch (e) {
  console.log('index.js', e.message);
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
