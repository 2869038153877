import { css } from '@emotion/react';
import { Popper } from '@mui/material';
import styled from '@emotion/styled';

export const TablePopper = styled(Popper)(
  ({ theme }) => css`
    .MuiPaper-root {
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
      border-radius: 10px;
      box-shadow: ${theme.shadowCA(2)};
    }
  `
);
