import React, { useEffect, useRef, useState } from 'react';
import Filters from './Filters';
import Table from './Table';
import DateRangeDrawer from './DateRangeDrawer';
import DateTypeSelector from './DateTypeSelector';
import { EmptyState, FiltersConfigPanel, Icon } from 'core';
import { Box, IconButton, Stack } from '@mui/material';

import { FILTERS_OPTIONS } from 'stores/traces/traces.store';
import { useTracesStore, useTracesInUseStore, useGlobalStore } from 'stores';
import { useParams } from 'react-router-dom';
import TraceSearchFilters from './TraceSearchFilters';

const TracesView = () => {
  const [openConfig, setOpenConfig] = useState(false);
  const { entityRef = '9d98a53e-75f9-47bb-8ef9-62e66330d10e' } = useParams();
  const { userSettings } = useGlobalStore();
  const {
    dateTypeFilter,
    tracesByFiltersAndSearch,
    error,
    initialize,
    isEmptyByFiltersAndSearch,
    isEmptyByResponse,
    isLoading,
    onChangeActiveFilters,
  } = useTracesStore();

  const { haveTracesInUseReadyToInvoice } = useTracesInUseStore();

  const isEmpty = isEmptyByResponse || isEmptyByFiltersAndSearch;
  const showTraceActionButtons = haveTracesInUseReadyToInvoice;

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (!dataFetchedRef.current) initialize(entityRef);

    dataFetchedRef.current = true;
  }, [initialize, entityRef]);

  const handleConfigPanel = () => setOpenConfig(prevOpenConfig => !prevOpenConfig);

  if (error) {
    return <p>Error</p>;
  }

  return (
    <Box className="tabs-content">
      {dateTypeFilter === 'Custom' ? <DateRangeDrawer /> : null}
      <FiltersConfigPanel
        activeFilters={userSettings?.trace?.activeFilters}
        filters={FILTERS_OPTIONS}
        onChangeSettings={onChangeActiveFilters}
        onOpenPanel={setOpenConfig}
        openPanel={openConfig}
      />
      <Stack className="filter-container">
        <Filters
          activeFilters={userSettings?.trace?.activeFilters}
          disabled={isLoading || isEmpty}
          options={FILTERS_OPTIONS}
        />
        <Stack className="extra-filters">
          <TraceSearchFilters />
          <DateTypeSelector />
          <IconButton className="settings-button" onClick={handleConfigPanel}>
            <Icon type="setting" />
          </IconButton>
        </Stack>
      </Stack>
      <Box className="content-container">
        {isEmpty && !isLoading ? (
          <EmptyState />
        ) : (
          <Table data={tracesByFiltersAndSearch} activeColumns={userSettings.trace.columnsActive} />
        )}
      </Box>
    </Box>
  );
};

export default TracesView;
