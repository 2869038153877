import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { Button as CoreButton } from 'core';

export const FiltersWrapper = styled(Stack)(
  () => css`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  `
);

export const Button = styled(CoreButton)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 6px 8px;
    border: 1px solid ${theme.colorsCA.neutral[50]};
    border-radius: 8px;
    box-shadow: ${theme.shadowCA(1)};
    cursor: pointer;
    white-space: nowrap;
    font-size: 1rem;
    line-height: 1rem;
    max-height: 32px;
    background: ${theme.backgroundMode};
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[30], dark: theme.colorsCA.neutral[95] })};

    &:hover {
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[95], dark: theme.colorsCA.neutral[50] })};
      border: 1px solid ${theme.colorsCA.neutral[50]};
    }

    &:disabled {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
      border-color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
      pointer-events: none;

      .icon,
      .icon:first-of-type {
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
      }
    }

    &.active {
      color: ${theme.colorsCA.neutral[100]};
      background: ${theme.colorsCA.aqua[30]};
      border: 1px solid ${theme.colorsCA.aqua[30]};

      .icon:first-of-type {
        color: ${theme.colorsCA.yellow[40]};
      }
    }

    .icon:first-of-type {
      font-size: 18px;
      color: ${theme.colorsCA.aqua[30]};
    }
  `
);
