import React from 'react';
import cn from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

import * as S from './style';

const Loader = ({ autoHeight }) => {
  return (
    <S.Wrapper className={cn({ autoHeight })}>
      <CircularProgress data-testid="circular-progress" color="inherit" />
    </S.Wrapper>
  );
};

Loader.propTypes = {
  autoHeight: PropTypes.bool,
};

export { Loader };
