import {
  SearchRounded,
  HighlightOff,
  History,
  Clear,
  ArrowDropDown,
  ArrowDropUp,
  PriceCheckOutlined,
  AttachMoneyOutlined,
  CurrencyExchangeOutlined,
  CreditCardOffOutlined,
  CheckCircleOutline,
  NotInterestedOutlined,
  StickyNote2Rounded,
  CheckOutlined,
  CheckCircleRounded,
  ExpandMoreRounded,
  ExpandLessRounded,
  DeleteRounded,
  AddCircleRounded,
  CancelRounded,
  ErrorOutlined,
  AddRounded,
  CancelOutlined,
  CloseOutlined,
  CategoryOutlined,
  ReceiptOutlined,
  ReceiptLongOutlined,
  PageviewOutlined,
  PreviewOutlined,
  DownloadForOfflineOutlined,
  FileDownloadOutlined,
  LocalActivityOutlined,
  PendingRounded,
  RefreshOutlined,
  RemoveRedEyeRounded,
  FactoryOutlined,
  StorefrontOutlined,
  FoundationOutlined,
  LocationOnOutlined,
  WatchLaterOutlined,
  EditOutlined,
  Check,
  SettingsRounded,
  SettingsSuggestRounded,
  FilterAlt,
} from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Order } from 'assets/icons/order-icon.svg';
import { ReactComponent as Customer } from 'assets/icons/customer-icon.svg';
import { ReactComponent as Ticket } from 'assets/icons/ticket-icon.svg';
import { ReactComponent as Project } from 'assets/icons/project-icon.svg';
import { ReactComponent as Location } from 'assets/icons/location-icon.svg';
import { ReactComponent as Truck } from 'assets/icons/truck-icon.svg';
import { ReactComponent as User } from 'assets/icons/user-icon.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar-icon.svg';
import { ReactComponent as Report } from 'assets/icons/report-icon.svg';
import { ReactComponent as Wheel } from 'assets/icons/wheel-icon.svg';

const iconNames = {
  search: props => <SearchRounded {...props} />,
  empty: props => <HighlightOff {...props} />,
  history: props => <History {...props} />,
  deleteHistory: props => <Clear {...props} />,
  order: props => <SvgIcon inheritViewBox component={Order} {...props} />,
  ticket: props => <SvgIcon inheritViewBox component={Ticket} {...props} />,
  project: props => <SvgIcon inheritViewBox component={Project} {...props} />,
  customer: props => <SvgIcon inheritViewBox component={Customer} {...props} />,
  location: props => <SvgIcon inheritViewBox component={Location} {...props} />,
  vehicle: props => <SvgIcon inheritViewBox component={Truck} {...props} />,
  product: props => <CategoryOutlined {...props} />,
  user: props => <SvgIcon inheritViewBox component={User} {...props} />,
  receipt: props => <ReceiptOutlined {...props} />,
  receiptLong: props => <ReceiptLongOutlined {...props} />,
  localActivity: props => <LocalActivityOutlined {...props} />,
  arrowDown: props => <ArrowDropDown {...props} />,
  arrowUp: props => <ArrowDropUp {...props} />,
  priceStatus: props => <PriceCheckOutlined {...props} />,
  taxStatus: props => <AttachMoneyOutlined {...props} />,
  billableStatus: props => <CurrencyExchangeOutlined {...props} />,
  voidedStatus: props => <CreditCardOffOutlined {...props} />,
  isBillable: props => <CheckCircleOutline {...props} />,
  notBillable: props => <NotInterestedOutlined {...props} />,
  hasNotes: props => <StickyNote2Rounded {...props} />,
  check: props => <CheckOutlined {...props} />,
  checkRound: props => <CheckCircleRounded {...props} />,
  calendar: props => <SvgIcon inheritViewBox component={Calendar} {...props} />,
  expandMore: props => <ExpandMoreRounded {...props} />,
  expandLess: props => <ExpandLessRounded {...props} />,
  deleteItem: props => <DeleteRounded {...props} />,
  addItem: props => <AddCircleRounded {...props} />,
  cancelAddItem: props => <CancelRounded {...props} />,
  cancelOutlined: props => <CancelOutlined {...props} />,
  closeOutlined: props => <CloseOutlined {...props} />,
  add: props => <AddRounded {...props} />,
  circleExclamation: props => <ErrorOutlined {...props} />,
  pageView: props => <PageviewOutlined {...props} />,
  preview: props => <PreviewOutlined {...props} />,
  pdfPreview: props => <RemoveRedEyeRounded {...props} />,
  downloadPdf: props => <DownloadForOfflineOutlined {...props} />,
  exportInvoice: props => <FileDownloadOutlined {...props} />,
  pending: props => <PendingRounded {...props} />,
  report: props => <SvgIcon inheritViewBox component={Report} {...props} />,
  refresh: props => <RefreshOutlined {...props} />,
  factory: props => <FactoryOutlined {...props} />,
  store: props => <StorefrontOutlined {...props} />,
  foundation: props => <FoundationOutlined {...props} />,
  locationOn: props => <LocationOnOutlined {...props} />,
  wheel: props => <SvgIcon inheritViewBox component={Wheel} {...props} />,
  watch: props => <WatchLaterOutlined {...props} />,
  edit: props => <EditOutlined {...props} />,
  simpleCheck: props => <Check {...props} />,
  setting: props => <SettingsRounded {...props} />,
  settingSuggest: props => <SettingsSuggestRounded {...props} />,
  traceLevelStatus: props => <FilterAlt {...props} />,
};

export default iconNames;
