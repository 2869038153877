import React, { useState } from 'react';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { TitleMenuContainer, MenuContainer, MenuItemContainer } from './styles';
import cn from 'classnames';

const TitleMenu = ({ menuItems = null, setCurrentTab = () => {}, currentTab = null, icon }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = item => {
    setAnchorEl(null);
    if (item !== undefined && item !== null) {
      setCurrentTab(item);
    }
  };
  return (
    <>
      <TitleMenuContainer
        id="title-button"
        aria-controls={open ? 'title-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={cn('title-menu-button', { 'is-open': open })}
      >
        {icon}
        <Box
          sx={{
            fontFamily: 'Work Sans',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '28px',
            textAlign: 'left',
          }}
        >
          {menuItems?.find(item => item.value === currentTab)?.label}
        </Box>
        <ArrowDropDownIcon className={open ? 'icon-rotate' : ''} />
      </TitleMenuContainer>
      <MenuContainer
        id="title-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        className="options-container"
        MenuListProps={{
          'aria-labelledby': 'title-button',
        }}
      >
        {menuItems?.map((item, index) => (
          <MenuItemContainer key={item.value + index} onClick={() => handleClose(item.value)}>
            {item.value === currentTab && <CheckRoundedIcon />}
            {item.label}
          </MenuItemContainer>
        ))}
      </MenuContainer>
    </>
  );
};

export default TitleMenu;
