import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Stack, Box } from '@mui/material';
import DetailActionButtons from './DetailActionButtons';
import { TraceEventsTable } from 'app-entities/trace/components';
import { DATAGRID_COLUMNS } from './RowDetail.helper';
import * as S from './style';
import cn from 'classnames';
import { uniqueId } from 'lodash';
import { useNotification, Localization } from 'connex-cds';
import { TRACE_ENTITY } from 'app-entities/trace';
import { useTracesStore } from 'stores';

const RowDetail = ({ className: classNameProp, data, traceRef }) => {
  const { entityRef } = useParams();
  const [dataGridKey, setDataGridKey] = useState(uniqueId('datagrid'));

  const { replaceDexa } = useTracesStore();

  const { mutateAsync, isLoading } = TRACE_ENTITY.appLogic().Update();

  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const [countForRowsEdits, setCountForRowsEdits] = useState(0);
  const [countForRowsDelete, setCountForRowsDelete] = useState(0);
  const [currentRows, setCurrentRows] = useState([]);

  const handleRowsChange = useCallback(rows => setCurrentRows(rows), []);

  const handleRowsUpdatesCountChange = useCallback(rowsUpdatesCount => {
    setCountForRowsEdits(rowsUpdatesCount?.countForEdit || 0);
    setCountForRowsDelete(rowsUpdatesCount?.countForDelete || 0);
  }, []);

  const handleSaveChanges = useCallback(async () => {
    const payload = { lineItems: currentRows };

    try {
      // const traceUpdated = await mutateAsync({ entityRef, traceRef, payload });
      // replaceDexa(traceUpdated);

      notification.success(translateMessage('gridDetailChanges_sucess'));
      setDataGridKey(uniqueId('datagrid'));
    } catch {
      notification.error(translateMessage('genericError'));
    }
  }, [mutateAsync, notification, replaceDexa, currentRows, translateMessage, entityRef, traceRef]);

  const handleDiscardChanges = () => setDataGridKey(uniqueId('datagrid'));

  return (
    <S.RowDetail className={cn('detail-container', classNameProp)}>
      <Stack className="inner-detail-container">
        <Box className="grid-wrapper">
          <TraceEventsTable
            key={dataGridKey}
            columnKeys={DATAGRID_COLUMNS}
            rows={data}
            onRowsChange={handleRowsChange}
            onUpdatesCountChange={handleRowsUpdatesCountChange}
            isLoading={isLoading}
          />
        </Box>
        <DetailActionButtons
          countForDelete={countForRowsDelete}
          countForEdit={countForRowsEdits}
          onDiscardChanges={handleDiscardChanges}
          onSaveChanges={handleSaveChanges}
          isLoading={isLoading}
        />
      </Stack>
    </S.RowDetail>
  );
};

RowDetail.propTypes = {
  traceRef: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
};

export default RowDetail;
