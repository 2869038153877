export const getPermittedMenuConfig = ({ menuConfig = [], userContext, entityContext }) => {
  const { user, role } = userContext;
  const entitlements = entityContext?.entitlements;

  if (!user || !role || !entitlements) {
    return menuConfig;
  }

  const filterConfig = config => {
    // Check if the config has permissionsApp and if the user has the required entitlements
    if (config?.permissionsApp?.length) {
      if (!entitlements?.apps?.some(entitlement => config.permissionsApp.includes(entitlement.crn))) {
        return false;
      }
    }

    // Check if the config has permissionsSKU and if the user has the required entitlements
    if (config?.permissionsSKU?.length) {
      if (!entitlements?.entitlements?.some(entitlement => config.permissionsSKU.includes(entitlement.crn))) {
        return false;
      }
    }

    if (user?.isPlatformAdmin || user?.isPlatformSupport) return true;

    // Check if the config has permissions and if the user has the required role permissions
    const configPermissions = [].concat(config?.permissions).filter(permission => !!permission);
    if (configPermissions?.length) {
      if (role?.permissions?.include?.('*')) return true;
      return configPermissions?.[config.permissionStrategy || 'some']?.(p => role?.permissions?.includes(p));
    }

    return true;
  };

  // Temporary workaround to support both menuConfig shapes until everyone has changed to the new format.
  const permitted = (menuConfig?.sidebarOptions || menuConfig).reduce((acc, item) => {
    if (!filterConfig(item)) {
      return acc;
    }

    // Filter the children
    const filteredChildren = item?.children?.filter(filterConfig) || [];

    // If the only child is the home page, don't show the parent
    if (filteredChildren?.length === 1 && filteredChildren[0]?.isHomePage) {
      return acc;
    }

    const updatedItem = {
      ...item,
      children: filteredChildren,
    };

    acc.push(updatedItem);
    return acc;
  }, []);

  if (menuConfig.sidebarOptions) {
    return {
      ...menuConfig,
      sidebarOptions: permitted,
    };
  }

  return permitted;
};
