import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InputAmount = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 8px;
    align-items: center;

    > p {
      margin: 0;
    }

    &.is-edited {
      > p {
        color: ${theme.colorsCA.orange[40]};
      }
    }
  `
);
