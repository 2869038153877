import { createContext, useContext, useState } from 'react';

import useLocalStorage from 'hooks/useLocalStorage';
import { TracesStoreProvider, TracesInUseStoreProvider } from './traces';

const INITIAL_STATE = {
  searchTerm: '',
  onChangeSearchTerm: () => {},
  userSettings: {
    searchHistory: [],
    invoiced: {
      activeFilters: {},
      columnsActive: {},
    },
    trace: {
      activeFilters: {},
      columnsActive: {},
    }
  },
  onChangeUserSettings: () => {},
};

const GlobalStoreContext = createContext(INITIAL_STATE);
const GlobalStoreProvider = ({ children }) => {
  const [userSettings, setUserSettings] = useLocalStorage('userSettings', INITIAL_STATE.userSettings);
  const [searchTerm, setSearchTerm] = useState(INITIAL_STATE.searchTerm);

  const handleChangeSearchTerm = newSearchTerm => setSearchTerm(newSearchTerm);

  const handleChangeUserSettings = newSearchTerm => setUserSettings(newSearchTerm);

  return (
    <GlobalStoreContext.Provider
      value={{
        searchTerm,
        onChangeSearchTerm: handleChangeSearchTerm,
        userSettings,
        onChangeUserSettings: handleChangeUserSettings,
      }}
    >
      <TracesStoreProvider>
        <TracesInUseStoreProvider>{children}</TracesInUseStoreProvider>
      </TracesStoreProvider>
    </GlobalStoreContext.Provider>
  );
};

const useGlobalStore = () => {
  const { searchTerm, onChangeSearchTerm, userSettings, onChangeUserSettings } = useContext(GlobalStoreContext);
  return {
    searchTerm,
    onChangeSearchTerm,
    userSettings,
    onChangeUserSettings,
  };
};

export { GlobalStoreContext, GlobalStoreProvider, useGlobalStore };
