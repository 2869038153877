export default {
  sidebarOptions: [
    {
      id: 'traces',
      labelStringId: 'traces',
      path: '/',
      iconName: 'Edit',
      defaultFeature: true,
    },
  ],
};
