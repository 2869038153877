import { sortBy } from 'lodash';

export const sortAndShiftHomePage = (menuItems, sortMode) => {
  // Sort children if necessary
  const sortedMenuItems = sortMode === 'default' ? menuItems : sortBy(menuItems || [], item => item.label);

  // Shift home page to top
  const homePageIndex = sortedMenuItems.findIndex(item => item?.isHomePage);

  if (homePageIndex > 0) {
    const [homePageItem] = sortedMenuItems.splice(homePageIndex, 1);
    sortedMenuItems.unshift(homePageItem);
  }

  return sortedMenuItems;
};
