import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Box, Menu, MenuItem } from '@mui/material';

export const TitleMenuContainer = styled(Box)`
  ${({ theme }) => css`
        display: flex;
        padding: 6px 8px 6px 10px;
        margin-left: -8px;
        color: inherit;
        align-items: center;
        gap: 6px;
        background-color: transparent;
        border-radius: 100px;
        &:focus{
          background-color: transparent !important;
        }
        &:hover{
          cursor: pointer;
        }
        &.is-open{
        background-color: ${theme.validateMode({
          light: theme.colorsCA.blue[95],
          dark: theme.colorsCA.darkBlue[60],
        })};
        .icon-rotate {
          transform: rotate(180deg);
        }
    `}
`;

export const MenuContainer = styled(Menu)`
  ${({ theme }) => css`
    &.options-container {
      .MuiPaper-root {
        margin-top: 8px;
        border-radius: 10px;
        font-family: Work Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        background: ${theme.validateMode({
          light: theme.colorsCA.neutral[100],
          dark: 'linear-gradient(0deg, rgba(208, 188, 255, 0.12) 0%, rgba(208, 188, 255, 0.12) 100%), #1C1B1F',
        })} !important;
      }
    }
  `}
`;

export const MenuItemContainer = styled(MenuItem)`
  ${({ theme }) => css`
    display: flex;
    gap: 8px;
    min-height: 3rem !important;
  `}
`;
