import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { DefaultTrigger, MenuItem } from './components';

import * as S from './style';

const SelectorMenu = ({
  triggerComponent: TriggerComponentProp,
  options,
  labelStartIcon,
  disabled,
  value,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = useMemo(() => !!anchorEl, [anchorEl]);

  const handleClickTrigger = e => setAnchorEl(e.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const handleSelectorChange = option => {
    handleCloseMenu();
    onChange(option);
  };

  const labelTranslationId = useMemo(() => {
    const selectedOption = options.find(option => option.value === value);

    const translation = selectedOption.translation;
    const isStr = typeof translation === 'string';

    if (isStr) {
      return translation;
    }

    return translation.onSelectedId || translation.id;
  }, [options, value]);

  const Trigger = useCallback(
    props => {
      if (TriggerComponentProp) {
        return <TriggerComponentProp {...props} />;
      }

      return <DefaultTrigger startIcon={labelStartIcon} {...props} />;
    },
    [TriggerComponentProp, labelStartIcon]
  );

  return (
    <>
      <Trigger isOpen={isOpen} onClick={handleClickTrigger} disabled={disabled} translationId={labelTranslationId} />
      <S.Menu anchorEl={anchorEl} open={isOpen} onClose={handleCloseMenu}>
        {options?.map(option => (
          <MenuItem
            key={option.value}
            translation={option.translation}
            isSelected={option.value === value}
            onClick={() => handleSelectorChange(option)}
          />
        ))}
      </S.Menu>
    </>
  );
};

SelectorMenu.propTypes = {
  disabled: PropTypes.bool,
  triggerComponent: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      translation: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.string,
          onSelectedId: PropTypes.string,
        }),
      ]),
    })
  ),
  labelStartIcon: PropTypes.element,
  onChange: PropTypes.func,
};

SelectorMenu.defaultProps = {};

export { SelectorMenu };
