import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const BottomDrawer = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;

    @media screen and (max-width: 1280px) {
      width: 400px;
    }

    .MuiStack-root {
      background: ${theme.backgroundMode};
      border-radius: 20px 20px 0px 0px;
      border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#49454F' })};
      border-bottom: none;
      bottom: 0;
      box-shadow: 0px 4px 20px 0px rgba(101, 119, 136, 0.2);
      padding: 20px;
      position: fixed;
      z-index: 12;
      width: 600px;
    }

    .icon {
      font-size: 22px;
      margin-right: 10px;
    }
  `
);
