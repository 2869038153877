import React from 'react';
import { SelectorMenu } from 'core';
import PropTypes from 'prop-types';
import { useTracesStore, useTracesInUseStore } from 'stores';
import FilterButton from './FilterButton';
import * as S from './style';

const Filters = ({ disabled, activeFilters, options }) => {
  const { traceLevelFilterValue, minorFilters, onChangeTraceLevelFilter, onChangeMinorFilters } = useTracesStore();
  const { clearTracesInUse } = useTracesInUseStore();

  const handleChangeTraceLevel = option => {
    onChangeTraceLevelFilter(option.value);

    onChangeMinorFilters(prevFilters => ({
      ...prevFilters,
      traceLevel: option.value,
    }));

    clearTracesInUse();
  };

  return (
    <S.FiltersWrapper>
      {activeFilters.traceLevel && (
        <SelectorMenu
          disabled={disabled}
          onChange={handleChangeTraceLevel}
          options={options?.traceLevel?.options || []}
          value={traceLevelFilterValue}
          triggerComponent={({ isOpen, ...props }) => (
            <FilterButton
              isActive={minorFilters.traceLevel !== null}
              isDropdown
              isPressed={isOpen}
              type="traceLevelStatus"
              {...props}
            />
          )}
        />
      )}
    </S.FiltersWrapper>
  );
};

Filters.propTypes = {
  activeFilters: PropTypes.object,
  disabled: PropTypes.bool,
  options: PropTypes.object,
};

export default Filters;
