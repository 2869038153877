import PropTypes from 'prop-types';
import { Loader } from 'core';
import { TraceEventsTableProvider } from './contexts';
import { LineItemsDataGrid } from './TraceEventsDataGrid';
import { useDataGridColumns } from './TraceEventsDataGrid.helper.js';

const TraceEventsTable = ({ rows, isLoading, onRowsChange, onUpdatesCountChange, columnKeys }) => {
  const { columnsConfig, fieldsToWatch } = useDataGridColumns(columnKeys);

  if (isLoading) {
    return <Loader autoHeight />;
  }

  return (
    <TraceEventsTableProvider
      rows={rows}
      isLoading={isLoading}
      onRowsChange={onRowsChange}
      onUpdatesCountChange={onUpdatesCountChange}
      fieldsToWatch={fieldsToWatch}
    >
      <LineItemsDataGrid columns={columnsConfig} />
    </TraceEventsTableProvider>
  );
};

TraceEventsTable.propTypes = {
  rows: PropTypes.array,
  isLoading: PropTypes.bool,
  onRowsChange: PropTypes.func,
  onUpdatesCountChange: PropTypes.func,
  columnKeys: PropTypes.arrayOf(PropTypes.string),
};

export { TraceEventsTable };
