import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InputNumber = styled.input(
  ({ theme }) => css`
    box-sizing: content-box;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    text-align: right;
    border-bottom: 1px solid ${theme.colorsCA.neutral[80]};
    padding-top: 2px;
    padding-bottom: 2px;

    &.is-edited {
      border-color: ${theme.colorsCA.orange[40]};
      color: ${theme.colorsCA.orange[40]};
    }
  `
);
