import React, { createContext, useContext, useState } from 'react';

import { createPortal } from 'react-dom';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);

  const openModal = content => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalContent && createPortal(modalContent, document.body)}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const { openModal, closeModal } = useContext(ModalContext);

  return { openModal, closeModal };
};
