import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { HeaderAndSidebar, Localization, useDefaultRoute, useHeaderText } from 'connex-cds';

import AuthenticatedRoutes from 'views/authenticated-routes/AuthenticatedRoutes';
import { RedirectToEntity } from 'views/authenticated-routes/RedirectToEntity';

import Home from 'views/Home';
import TracesView from 'components/traces';

export const AppRoutes = () => {
  const defaultRoute = useDefaultRoute('tabs/traces');
  const translateMessage = Localization.useTranslateMessage();

  const { setHeaderText } = useHeaderText();

  useEffect(() => {
    setHeaderText(translateMessage('traces'));
  }, [setHeaderText, translateMessage]);

  return (
    <Routes>
      <Route element={<HeaderAndSidebar />}>
        <Route path="" element={<AuthenticatedRoutes />}>
          <Route path="" element={<RedirectToEntity />}>
            <Route path=":entityRef/*">
              <Route path="tabs" element={<Home />}>
                <Route path="traces" element={<TracesView />} />
              </Route>
              <Route path="*" element={<Navigate to={defaultRoute} />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
