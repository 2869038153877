import React from 'react';

import { SelectorMenu, Icon } from 'core';
import { useTracesStore, useTracesInUseStore } from 'stores';

const selectorOptions = [
  {
    value: 'Past_5_minutes',
    translation: 'dateSelector_past_5_minutes',
  },
  {
    value: 'Past_15_minutes',
    translation: 'dateSelector_past_15_minutes',
  },
  {
    value: 'Past_30_minutes',
    translation: 'dateSelector_past_30_minutes',
  },
  {
    value: 'Past_hour',
    translation: 'dateSelector_past_hour',
  },
  {
    value: 'Past_3_hours',
    translation: 'dateSelector_past_3_hours',
  },
  {
    value: 'Past_6_hours',
    translation: 'dateSelector_past_6_hours',
  },
  {
    value: 'Today',
    translation: 'dateSelector_today',
  },
  {
    value: 'Yesterday',
    translation: 'dateSelector_yesterday',
  },
  {
    value: 'Custom',
    translation: 'dateSelector_custom',
  },
];

const DateTypeSelector = () => {
  const { isLoading, dateTypeFilter, onChangeDateTypeFilter } = useTracesStore();
  const { clearTracesInUse } = useTracesInUseStore();

  const handleChange = option => {
    onChangeDateTypeFilter(option.value);
    clearTracesInUse();
  };

  return (
    <SelectorMenu
      value={dateTypeFilter}
      disabled={isLoading}
      labelStartIcon={<Icon type="calendar" />}
      options={selectorOptions}
      onChange={handleChange}
    />
  );
};

export default DateTypeSelector;
