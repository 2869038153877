import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import * as S from './style';

const PagePaper = ({ children }) => {
  return (
    <S.PagePaper>
      <Box className="inner-wrapper">{children}</Box>
    </S.PagePaper>
  );
};

PagePaper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

PagePaper.Header = S.Header;
PagePaper.Footer = S.Footer;

export default PagePaper;
