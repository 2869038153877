import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GridColumnMenu } from '@mui/x-data-grid-pro';

export const ColumnMenu = styled(GridColumnMenu)(
  ({ theme }) => css`
    .MuiListItemIcon-root,
    .MuiListItemText-root {
      color: ${theme.palette.text.primary};
    }
  `
);
