import cn from 'classnames';
import PropTypes from 'prop-types';

import * as S from './style';

const BottomDrawer = ({ children, className }) => {
  return <S.BottomDrawer className={cn(className)}>{children}</S.BottomDrawer>;
};

BottomDrawer.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
};

export { BottomDrawer };
