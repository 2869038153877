import { useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './style';
import { isEqual } from 'lodash';
import { Localization } from 'connex-cds';
import { Box, Button, FormControlLabel, Switch, Typography } from '@mui/material';
import { Icon } from '../Icon';

const FiltersConfigPanel = ({ openPanel, onOpenPanel, filters, activeFilters, onChangeSettings }) => {
  const translateMessage = Localization.useTranslateMessage();
  const [filtersState, setFiltersState] = useState(activeFilters);

  const getLabelTranslationId = translation => {
    const isString = typeof translation === 'string';

    if (isString) {
      return translation;
    }

    return translation.onSelectedId;
  };

  const handleChange = (event, filter) => {
    setFiltersState(prevActiveFilters => ({ ...prevActiveFilters, [filter]: event.target.checked }));
  };

  const handleCancel = () => {
    setFiltersState(activeFilters);
    onOpenPanel(false);
  };
  const handleSave = () => {
    onChangeSettings(filtersState);
    onOpenPanel(false);
  };

  return (
    <S.ConfigPanel open={openPanel} onClose={handleCancel} anchor="right">
      <S.Header>
        <Icon type="settingSuggest" />
        <Typography>{translateMessage('settings_header')}</Typography>
      </S.Header>
      <S.Content>
        {Object.keys(filters).map(filter => {
          return (
            <Box key={filter}>
              <FormControlLabel
                control={<Switch checked={filtersState[filter]} onChange={e => handleChange(e, filter)} />}
                label={translateMessage(getLabelTranslationId(filters[filter].options[0].translation))}
                labelPlacement="end"
              />
            </Box>
          );
        })}
      </S.Content>
      <S.Footer>
        <Button className="panel-button cancel-button" onClick={handleCancel}>
          {translateMessage('cancel')}
        </Button>
        <Button
          className="panel-button save-button"
          onClick={handleSave}
          disabled={isEqual(filtersState, activeFilters)}
        >
          {translateMessage('save')}
        </Button>
      </S.Footer>
    </S.ConfigPanel>
  );
};

FiltersConfigPanel.propTypes = {
  activeFilters: PropTypes.object,
  filters: PropTypes.object,
  onChangeSettings: PropTypes.func,
  onOpenPanel: PropTypes.func,
  openPanel: PropTypes.bool,
};

export { FiltersConfigPanel };
