import { createContext, useReducer, useContext, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Localization } from 'connex-cds';

// import { PRODUCT_ENTITY } from 'app-entities/product';
// import { UOM_ENTITY } from 'app-entities/uom';
// import { SORT_FNS } from 'utils';

import {
  CONTEXT_INITIAL_STATE,
  REDUCER_ACTIONS,
  REDUCER_UTILS,
  reducerFn,
} from './TraceEventsTable.context-helper';

const TraceEventsTableContext = createContext(CONTEXT_INITIAL_STATE);

const TraceEventsTableProvider = ({
  children,
  rows,
  isLoading,
  onRowsChange,
  onUpdatesCountChange,
  fieldsToWatch,
}) => {
  const { entityRef } = useParams();

  const translateMessage = Localization.useTranslateMessage();

  const [rowsState, dispatch] = useReducer(reducerFn, rows, REDUCER_UTILS.initialize);

  const countForEdit = useMemo(
    () =>
      rowsState.reduce((changesCounter, row) => {
        const editingStatusKeys = Object.keys(row.editingStatus);
        editingStatusKeys.forEach(objKey => {
          if (objKey === 'isMarkedToCreate') {
            changesCounter++;
            return;
          }

          if (
            objKey !== 'isMarkedToDelete' &&
            row.editingStatus[objKey] &&
            fieldsToWatch.some(field => field === objKey)
          ) {
            changesCounter++;
          }
        });

        return changesCounter;
      }, 0),
    [rowsState, fieldsToWatch]
  );

  const countForDelete = useMemo(
    () => rowsState.reduce((count, row) => (row.editingStatus.isMarkedToDelete ? count + 1 : count), 0),
    [rowsState]
  );

  // const {
  //   isLoading: isProductsLoading,
  //   data: products,
  //   isFetched: isProductsFetched,
  // } = PRODUCT_ENTITY.appLogic().List({
  //   entityRef,
  //   apiClientConfig: {
  //     staleTime: Infinity,
  //     enabled: !!entityRef,
  //   },
  // });
  // useEffect(() => {
  //   if (isProductsFetched) {
  //     dispatch({ type: REDUCER_ACTIONS.UPDATE_AFTER_PRODUCTS_LIST_LOADS, payload: products });
  //   }
  // }, [products, isProductsFetched]);

  // const {
  //   data: baseUoms,
  //   isLoading: isUomsLoading,
  //   isFetched: isUomsFetched,
  // } = UOM_ENTITY.appLogic().List({
  //   entityRef,
  //   apiClientConfig: {
  //     staleTime: Infinity,
  //     enabled: !!entityRef,
  //   },
  // });
  // const uoms = useMemo(() => {
  //   return baseUoms
  //     ?.map(uom => ({
  //       value: uom.id,
  //       label: `${translateMessage(`${uom.id}_text`, { value: 0 })} (${translateMessage(`${uom.id}_short_suffix`, {
  //         value: 0,
  //       })})`,
  //     }))
  //     .sort(SORT_FNS.ALPHA_SORT('label'));
  // }, [baseUoms, translateMessage]);
  // useEffect(() => {
  //   if (isUomsFetched && !!uoms) {
  //     dispatch({ type: REDUCER_ACTIONS.UPDATE_AFTER_UOMS_LIST_LOADS, payload: uoms });
  //   }
  // }, [uoms, isUomsFetched]);

  const rowsProductRefs = useMemo(() => rowsState.map(row => row.mutated.productRef), [rowsState]);

  const handleAddItems = payload => {
    // dispatch({ type: REDUCER_ACTIONS.ADD_ITEMS, payload: { items: payload, uoms, products } });
  };
  const handleChangeAmount = (fieldName, rowKey, value) =>
    dispatch({ type: REDUCER_ACTIONS.UPDATE_AMOUNT, payload: { fieldName, rowKey, value } });
  const handleChangeProduct = (rowKey, product) => {};
    // dispatch({ type: REDUCER_ACTIONS.UPDATE_PRODUCT_VALUE, payload: { product, rowKey, uoms } });
  const handleChangeProductInput = (rowKey, product) =>
    dispatch({ type: REDUCER_ACTIONS.UPDATE_PRODUCT_INPUT, payload: { product, rowKey } });
  const handleChangeUom = (rowKey, uom) =>
    dispatch({ type: REDUCER_ACTIONS.UPDATE_UOM_VALUE, payload: { uom, rowKey } });
  const handleChangeUomInput = (rowKey, uom) =>
    dispatch({ type: REDUCER_ACTIONS.UPDATE_UOM_INPUT, payload: { uom, rowKey } });
  const handleDeleteItem = rowKey => dispatch({ type: REDUCER_ACTIONS.DELETE_ITEM, payload: { rowKey } });

  useEffect(() => {
    const sanitazedRows = rowsState.flatMap(row => (row.editingStatus.isMarkedToDelete ? [] : [row.mutated]));
    onRowsChange?.(sanitazedRows);
  }, [onRowsChange, rowsState]);

  useEffect(() => {
    onUpdatesCountChange?.({ countForEdit, countForDelete });
  }, [onUpdatesCountChange, countForEdit, countForDelete]);

  return (
    <TraceEventsTableContext.Provider
      value={{
        rows: rowsState,
        rowsProductRefs,
        // products,
        // uoms,
        onAddItems: handleAddItems,
        onDeleteItem: handleDeleteItem,
        onChangeAmount: handleChangeAmount,
        onChangeProduct: handleChangeProduct,
        onChangeProductInput: handleChangeProductInput,
        onChangeUom: handleChangeUom,
        onChangeUomInput: handleChangeUomInput,
        // isProductsLoading,
        // isUomsLoading,
        isLoading,
      }}
    >
      {children}
    </TraceEventsTableContext.Provider>
  );
};

TraceEventsTableProvider.propTypes = {
  children: PropTypes.node.isRequired,
  rows: PropTypes.array,
  isLoading: PropTypes.bool,
  onRowsChange: PropTypes.func,
  onUpdatesCountChange: PropTypes.func,
  fieldsToWatch: PropTypes.arrayOf(PropTypes.string),
};

const useTraceEventsTableContext = () => {
  const {
    rows,
    rowsProductRefs,
    onChangeAmount,
    isProductsLoading,
    onChangeProduct,
    onChangeProductInput,
    products,
    uoms,
    onAddItems,
    onDeleteItem,
    onChangeUom,
    onChangeUomInput,
    isUomsLoading,
    isLoading,
  } = useContext(TraceEventsTableContext);

  return {
    rows,
    rowsProductRefs,
    onAddItems,
    onDeleteItem,
    onChangeAmount,
    onChangeProduct,
    onChangeProductInput,
    isProductsLoading,
    products,
    uoms,
    onChangeUom,
    onChangeUomInput,
    isUomsLoading,
    isLoading,
  };
};

export { TraceEventsTableProvider, useTraceEventsTableContext };
