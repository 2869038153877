import { css } from '@emotion/react';
import styled from '@emotion/styled';
import iconNames from './iconList';

export const Icon = type => {
  if (!type || !iconNames[type]) {
    console.error(`Icon ${type} not found`);
    return null;
  }
  return type && iconNames[type]
    ? styled(iconNames[type])(
        ({ theme }) => css`
          font-size: 24px;
        `
      )
    : null;
};
