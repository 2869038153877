import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled('div')(
  () => css`
    align-items: center;
    color: #c9c9c9;
    display: flex;
    height: 500px;
    justify-content: center;

    &.autoHeight {
      height: unset;
    }
  `
);
