import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const DataGridTable = styled(DataGridPro)(
  ({ theme, loading }) => css`
    flex: initial;
    border-radius: 10px;
    border: 1px solid ${theme.colorsCA.neutral[50]};
    box-shadow: ${theme.validateMode({ light: '0px 4px 20px rgba(101, 119, 136, 0.2)', dark: theme.shadowCA(4) })};
    overflow: hidden;
    height: ${loading ? '100%' : 'auto'};
    max-height: 100%;

    .MuiDataGrid-columnHeader:focus,
    .MuiDataGrid-columnHeader:focus-within,
    .MuiDataGrid-cell:focus,
    .MuiDataGrid-cell:focus-within {
      outline: none !important;
    }

    > .MuiDataGrid-main {
      > .MuiDataGrid-columnHeaders {
        border-bottom: 1px solid ${theme.colorsCA.neutral[50]};
        box-shadow: ${theme.shadowCA(1)};
        z-index: 3;
        overflow: hidden;
        background: ${theme.backgroundCA('tableHF')};
        .MuiDataGrid-columnHeader {
          padding: 0 12px;
          overflow: hidden;
          &::after {
            content: '';
            display: block;
            position: absolute;
            right: -4px;
            width: 4px;
            height: 100%;
            background: ${theme.validateMode({
              light: 'rgba(0, 0, 0, 0.25)',
              dark: 'rgba(255, 254, 254, 0.25)',
            })};
            filter: blur(2px);
          }

          &:first-of-type,
          &:last-of-type {
            &::after {
              display: none;
            }
          }
          &:not(.MuiDataGrid-columnHeader--alignCenter) {
            .MuiDataGrid-columnHeaderTitleContainer {
              justify-content: space-between;
            }
          }
          &:first-of-type {
            padding: 0;
          }

          &:hover {
            .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-menuIcon {
              margin: 0;
            }
          }

          .MuiDataGrid-columnHeaderTitleContainer {
            .MuiDataGrid-iconButtonContainer {
              color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
            }

            .MuiDataGrid-columnHeaderTitle {
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 130%;
              margin: 0;
            }
          }

          .MuiDataGrid-menuIcon {
            margin: 0;
            .MuiDataGrid-menuIconButton {
              background: ${theme.validateMode({
                light: theme.colorsCA.neutral[95],
                dark: theme.colorsCA.neutral[20],
              })};
              color: ${theme.validateMode({ light: '#79747E', dark: theme.colorsCA.neutral[90] })};
            }
          }
        }
      }

      > .MuiDataGrid-virtualScroller {
        overflow: ${loading ? 'hidden' : 'auto'};
        background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};

        .MuiDataGrid-overlayWrapper {
          .skeleton-cell {
            height: 54px;
          }
        }

        .MuiDataGrid-row.main-grid-row {
          border-bottom: 1px solid
            ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
          background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
          cursor: pointer;

          &:hover {
            background-color: rgba(0, 0, 0, 0.04);

            &.green {
              background: ${theme.validateMode({ light: 'rgba(0, 205, 100, 0.30)', dark: 'rgba(0, 205, 100, 0.30)' })};
            }
      
            &.yellow {
              background: ${theme.validateMode({ light: 'rgba(251, 176, 64, 0.30)', dark: 'rgba(251, 176, 64, 0.30)' })};
            }
      
            &.red {
              background: ${theme.validateMode({ light: 'rgba(213, 0, 0, 0.30)', dark: 'rgba(213, 0, 0, 0.30)' })};
            }
      
            &.black {
              background: ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.30)', dark: 'rgba(0, 0, 0, 0.30)' })};
            }
          }

          &.MuiDataGrid-row--detailPanelExpanded,
          &:last-of-type {
            border-bottom: none;
          }
          &.Mui-selected {
            background: ${theme.validateMode({
              light: theme.colorsCA.blue[95],
              dark: theme.colorsCA.darkBlue[60],
            })};
            &.voided {
              background: ${theme.validateMode({ light: '#FFF6F7', dark: '#986977' })};
            }
          }
          &::hover,
          &.Mui-hovered {
            background: ${theme.validateMode({
              light: theme.surfaceCA(1),
              dark: 'linear-gradient(0deg, rgba(208, 188, 255, 0.14) 0%, rgba(208, 188, 255, 0.14) 100%), #434247',
            })};
            &.voided {
              background: ${theme.validateMode({ light: '#FFF6F7', dark: '#633B48' })};
            }
          }

          &.voided {
            background: ${theme.validateMode({ light: '#FFF6F7', dark: '#492532' })};
          }

          &.green {
            background: ${theme.validateMode({ light: 'rgba(0, 205, 100, 0.15)', dark: 'rgba(0, 205, 100, 0.15)' })};
          }
    
          &.yellow {
            background: ${theme.validateMode({ light: 'rgba(251, 176, 64, 0.15)', dark: 'rgba(251, 176, 64, 0.15)' })};
          }
    
          &.red {
            background: ${theme.validateMode({ light: 'rgba(213, 0, 0, 0.15)', dark: 'rgba(213, 0, 0, 0.15)' })};
          }
    
          &.black {
            background: ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.15)', dark: 'rgba(0, 0, 0, 0.15)' })};
          }

          .MuiDataGrid-cell {
            border: none;

            .detail-toggler-button {
              width: 24px;
              height: 24px;
              padding: 0;
              color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
            }

            .icon {
              &.state {
                color: ${theme.colorsCA.red[60]};
                font-size: 25px;
              }

              &.billable {
                color: ${theme.colorsCA.green[60]};
              }

              &.notes {
                color: ${theme.colorsCA.blue[50]};
              }
            }

            .MuiDataGrid-cellContent {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
              white-space: nowrap;
            }
          }
        }

        .MuiDataGrid-detailPanel {
          overflow: initial;
          border-bottom: 1px solid
            ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
        }
      }
    }
  `
);
