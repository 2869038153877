import PropTypes from 'prop-types';
import cn from 'classnames';
import { Core } from 'connex-cds';

import * as S from './style';

const { useNumberInput } = Core;

const InputNumber = props => {
  const { className, ...inputProps } = useNumberInput({ ...props, decimalPlaces: 2 });

  return <S.InputNumber className={cn(className, { 'is-edited': inputProps.isEdited })} {...inputProps} />;
};

InputNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  isEdited: PropTypes.bool,
  maxValue: PropTypes.number,
};

export { InputNumber };
