import React from 'react';
import * as S from './style';

const TableColumnMenu = props => {
  return (
    <S.ColumnMenu
      {...props}
      slots={{
        columnMenuPinningItem: null,
      }}
    />
  );
};

export { TableColumnMenu };
