import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Popper as MUIPopper, Autocomplete as MUIAutocomplete } from '@mui/material';

export const Autocomplete = styled(MUIAutocomplete)(
  ({ theme }) => css`
    width: fit-content;
    font-size: 16px;

    .MuiInputBase-root {
      width: 290px;
      height: 48px;
      padding: 12px 15px;
      border-radius: 10px;
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
      &.Mui-disabled {
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
        .icon {
          color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
        }
      }
    }

    .MuiOutlinedInput-root {
      .MuiAutocomplete-input {
        height: 100%;
        padding: 0 8px;

        &::placeholder {
          color: ${theme.validateMode({ light: theme.colorsCA.neutral[50], dark: theme.colorsCA.neutral[90] })};
        }

        &[aria-expanded='false'] ~ .MuiOutlinedInput-notchedOutline {
          border-bottom-width: 1px;
          border-radius: 10px;
        }
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${theme.colorsCA.blue[50]};
          border-width: 2px 2px 0;
        }

        .MuiAutocomplete-input[aria-expanded='false'] ~ .MuiOutlinedInput-notchedOutline {
          border-bottom-width: 2px;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 10px 10px 0 0;
      border-style: solid;
      border-color: ${theme.validateMode({ light: theme.colorsCA.neutral[50], dark: theme.colorsCA.neutral[90] })};
      border-width: 1px 1px 0;
      box-shadow: ${theme.shadowCA(2)};
    }

    .MuiInputAdornment-root {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }

    .icon {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[30], dark: theme.colorsCA.neutral[99] })};
      &.clean {
        font-size: 20px;
      }
    }
  `
);

export const Popper = styled(MUIPopper)(
  ({ theme }) => css`
    overflow: hidden;
    padding: 0 3px 3px;
    box-sizing: content-box;

    .MuiAutocomplete-paper {
      box-shadow: ${theme.shadowCA(2)};
      overflow: visible;
      background: transparent;
      border-radius: 0 0 10px 10px;
    }

    .MuiAutocomplete-listbox {
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
      overflow: visible;
      max-height: none;
      border-radius: 0 0 10px 10px;
      border-style: solid;
      border-color: ${theme.colorsCA.blue[50]};
      border-width: 0 2px 2px;

      .MuiAutocomplete-option {
        justify-content: space-between;
        gap: 16px;
      }
      .MuiStack-root {
        gap: 8px;
        align-items: center;
        width: 100%;
        .MuiBox-root {
          font-size: 16px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .MuiIconButton-root {
        width: 16px;
        height: 16px;
      }
    }

    .icon {
      font-size: 16px;
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[90] })};
      &.history {
        font-size: 13px;
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[70], dark: theme.colorsCA.neutral[80] })};
      }
    }
  `
);
