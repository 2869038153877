import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const LineItemsDataGrid = styled(DataGridPro)(
  ({ theme }) => css`
    border: none;

    > .MuiDataGrid-main {
      overflow: visible;

      .MuiDataGrid-columnHeaders {
        border: none;

        .MuiDataGrid-columnHeader {
          background: transparent;
          border: none;
          padding: 0 15px;

          &:focus,
          &:focus-within {
            outline: none !important;
          }

          &:hover {
            .MuiDataGrid-columnHeaderTitleContainer {
              justify-content: space-between;
            }
          }

          .MuiDataGrid-columnHeaderTitleContainerContent,
          .MuiDataGrid-columnHeaderTitle {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      .MuiDataGrid-virtualScroller {
        box-shadow: ${theme.shadowCA(2)};
        border-radius: 12px;

        .MuiDataGrid-row {
          border: none;
          position: relative;

          &.marked-to-delete {
            background: ${theme.validateMode({ light: '#FFECF1', dark: '#492532' })};
          }

          &.marked-to-create {
            background: ${theme.validateMode({ light: theme.colorsCA.orange[95], dark: '#FBA22D33' })};
          }

          .MuiDataGrid-cell {
            border: none;
            padding: 0 15px;

            &:focus,
            &:focus-within {
              outline: none !important;
            }
          }
        }

        .MuiDataGrid-pinnedRows {
          box-shadow: none;
          background: transparent;
        }

        .MuiDataGrid-overlayWrapper {
          display: none;
        }

        .MuiDataGrid-virtualScrollerContent {
          .MuiDataGrid-row {
            &::before {
              content: '';
              display: block;
              width: calc(100% - 30px);
              height: 1px;
              background: ${theme.colorsCA.neutral[80]};
              position: absolute;
              left: 50%;
              top: 0;
              transform: translateX(-50%);
              border-radius: 5px;
            }
          }
        }

        > *:first-child {
          .MuiDataGrid-row:first-child {
            &::before {
              display: none;
            }
          }
        }
      }
    }
  `
);
