import { isUndefined } from 'lodash';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocalizationContext } from '../../localization';

const parseNumber = (value, decimalSeparator) => {
  return Number.parseFloat(value?.replace(decimalSeparator, '.'));
};

const formatNumber = (value, userLocale, options) => {
  return Intl.NumberFormat(userLocale, options).format(value);
};

function sanitize(str, decimalSeparator, { allowNegatives, forceNegatives }) {
  if (!str) return '';
  const isNegative = str[0] === '-';
  const regex = new RegExp(`[^0-9${decimalSeparator}]`, 'g');

  const sanitized = str.replace(regex, '');
  const showNegativeSymbol = (allowNegatives && isNegative) || forceNegatives;

  const formatted = sanitized
    .split(decimalSeparator)
    .reduce((prev, curr, i) => prev + (i === 1 ? decimalSeparator : '') + curr, showNegativeSymbol ? '-' : '');
  return formatted;
}

export const useNumberInput = ({
  addOnAfter,
  value,
  onFocus,
  onBlur,
  onChange,
  testId,
  minValue,
  maxValue,
  addonAfter,
  decimalPlaces,
  allowNegatives,
  forceNegatives,
  ...props
}) => {
  const [_value, setValue] = useState(value?.toString() || '');
  const [formattedValue, setFormattedValue] = useState('');
  const { userLocale } = useLocalizationContext();
  const [mode, setMode] = useState('read');

  const formatNumberOptions = useMemo(() => {
    return isUndefined(decimalPlaces)
      ? {}
      : { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces, signDisplay: 'negative' };
  }, [decimalPlaces]);

  const decimalSeparator = useMemo(() => {
    const number = 1.1;
    const separator = Intl.NumberFormat(userLocale)
      .formatToParts(number)
      .find(part => part.type === 'decimal').value;

    return separator;
  }, [userLocale]);

  useEffect(() => {
    if (mode === 'edit') return;

    const currentValue = parseNumber(_value, decimalSeparator) || value;

    if (isNaN(currentValue)) {
      setValue('');
      setFormattedValue('');
      return;
    }

    const formattedValue = formatNumber(currentValue, userLocale, formatNumberOptions);
    const sanitizedValue = sanitize(formattedValue, decimalSeparator, { allowNegatives, forceNegatives });
    setValue(currentValue === 0 ? formattedValue : sanitizedValue);
    setFormattedValue(formattedValue);
  }, [
    decimalPlaces,
    decimalSeparator,
    mode,
    userLocale,
    value,
    _value,
    allowNegatives,
    forceNegatives,
    formatNumberOptions,
  ]);

  const handleChange = useCallback(
    e => {
      const strValue = e.target.value;

      const number = parseNumber(strValue, decimalSeparator);

      if (number > maxValue || number < minValue || strValue?.split?.(decimalSeparator)?.[1]?.length > decimalPlaces) {
        e.preventDefault();
        return;
      }

      const sanitizedValue = sanitize(strValue, decimalSeparator, { allowNegatives, forceNegatives });
      const sanitizedNumber = parseNumber(sanitizedValue, decimalSeparator);
      setValue(sanitizedNumber === 0 ? formatNumber(sanitizedNumber, userLocale, formatNumberOptions) : sanitizedValue);
      const lastCharacter = strValue?.slice?.(sanitizedValue.length - 1);
      if (lastCharacter !== decimalSeparator) {
        onChange?.(sanitizedNumber);
      }
    },
    [
      decimalPlaces,
      decimalSeparator,
      maxValue,
      minValue,
      onChange,
      userLocale,
      allowNegatives,
      forceNegatives,
      formatNumberOptions,
    ]
  );

  const handleFocus = useCallback(
    e => {
      setMode('edit');
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    e => {
      setMode('read');
      onChange?.(parseNumber(_value, decimalSeparator));
      setTimeout(() => {
        onBlur?.(e);
      }, 100);
    },
    [_value, onBlur, onChange, decimalSeparator]
  );

  const displayValue = useMemo(() => {
    return mode === 'edit' ? _value : formattedValue;
  }, [_value, formattedValue, mode]);

  return {
    lang: userLocale,
    onChange: handleChange,
    value: displayValue,
    onFocus: handleFocus,
    onBlur: handleBlur,
    ...props,
  };
};
