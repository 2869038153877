import { useMemo } from 'react';
import { Theme } from 'connex-cds';
import { createTheme } from '@mui/material/styles';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';

import getDesignConfig from '../modes/config';
import { Global } from '@emotion/react';
import GlobalStyles from './style';

const ThemeProvider = ({ children }) => {
  const { themeName } = Theme.useThemeContext();
  const theme = useMemo(() => createTheme(getDesignConfig(themeName)), [themeName]);
  const globalStyles = GlobalStyles({ theme: theme });

  return (
    <MuiThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
