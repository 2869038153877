import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Menu as MUIMenu, MenuItem as MUIMenuItem } from '@mui/material';
import { Button } from '../index';

export const MenuItem = styled(MUIMenuItem)(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2.25, 2)};
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-weight: 400;

    &:hover {
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[95], dark: theme.colorsCA.neutral[40] })};
    }

    &.selected {
      font-weight: 500;
      background-color: ${theme.validateMode({ light: theme.colorsCA.blue[95], dark: theme.colorsCA.darkBlue[60] })};
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
    }
  `
);

export const Menu = styled(MUIMenu)(
  ({ theme }) => css`
    .MuiPaper-root {
      min-width: 200px;
      margin-top: ${theme.spacing(1.4)};
      border-radius: 10px;
      box-shadow: ${theme.shadowCA(2)};
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
    }
  `
);

export const DefaultTrigger = styled(Button)(
  ({ theme }) => css`
    font-size: 16px;
    padding: 10px 17px;
    .MuiButton-startIcon,
    .MuiButton-endIcon {
      margin: 0;
    }
    .MuiButton-endIcon {
      .MuiSvgIcon-root {
        font-size: 24px;
      }
    }
  `
);
