import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GridPanel } from '@mui/x-data-grid-pro';

export const TablePanel = styled(GridPanel)(
  ({ theme }) => css`
    .MuiPaper-root {
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
      border-radius: 10px;
      box-shadow: ${theme.shadowCA(2)};
    }
  `
);
