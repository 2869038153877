export const SHOULD_DISABLE_DATE_VALIDATIONS = {
  max31Days: ({ datePosition, currentDates }) => {
    if (datePosition === 'end') {
      const startDate = currentDates[0];
      const endDate = currentDates[1];
      if (startDate && endDate) {
        const daysDiff = startDate.diff(endDate, 'day');
        const absDaysDiff = Math.abs(daysDiff);
        if (absDaysDiff >= 31) {
          return true;
        }
      }
    }

    return false;
  },
  startDateBeforeEndDate: ({ datePosition, currentErrors }) => {
    const startDateError = currentErrors[0];

    if (datePosition === 'end') {
      if (startDateError === 'maxDate') {
        return true;
      }
    }

    return false;
  },
};
