import { Button } from '@mui/material';
import { Localization } from 'connex-cds';
import cn from 'classnames';
import PropTypes from 'prop-types';

import * as S from '../style';

const ActionBar = ({ onAccept, className, disabled }) => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <S.ActionBar className={cn(className)}>
      <Button onClick={onAccept} disabled={disabled}>
        {translateMessage('done')}
      </Button>
    </S.ActionBar>
  );
};

ActionBar.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onAccept: PropTypes.func,
};

export { ActionBar };
