import { Localization } from 'connex-cds';
import PropTypes from 'prop-types';
import { IconButton, Stack } from '@mui/material';
import { GridToolbarColumnsButton } from '@mui/x-data-grid-pro';
import { Icon } from 'core';
import { useTracesInUseStore, useTracesStore } from 'stores';
import * as S from './style';

const TableFooter = ({ totalCount }) => {
  const translateMessage = Localization.useTranslateMessage();
  const { isLoading, onRefreshData } = useTracesStore();
  const { countTracesInUse } = useTracesInUseStore();

  return (
    <S.GridTableFooter>
      {!isLoading && (
        <>
          <Stack className="selection-wrapper">
            {countTracesInUse > 0 && (
              <p className="count-selected">
                {countTracesInUse} {translateMessage('gridFooter_selection', { value: countTracesInUse })}
              </p>
            )}
            <GridToolbarColumnsButton className="columns-button" />
          </Stack>
          <Stack className="records-wrapper">
            <p className="count-total">{`${translateMessage('gridFooter_count')} ${totalCount} ${translateMessage(
              'gridFooter_countRecord',
              { value: totalCount }
            )}`}</p>
            <IconButton onClick={onRefreshData} className="refresh-button">
              <Icon type="refresh" />
            </IconButton>
          </Stack>
        </>
      )}
    </S.GridTableFooter>
  );
};

TableFooter.propTypes = {
  totalCount: PropTypes.number,
};

export default TableFooter;
