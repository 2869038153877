import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Amount = styled.p(
  ({ theme }) => css`
    margin: 0;

    &.is-edited {
      color: ${theme.colorsCA.orange[40]};
    }
  `
);
