import colors from '../colors';
import { shadow, background } from '../elevation';

const darkOverrides = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          border: `1px solid ${colors.blue[95]}`,
        },
      },
    ],
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        background: background.light[5],
        shadow: shadow.light[5],
      },
    },
  },
  MuiItem: {
    styleOverrides: {
      root: {
        color: colors.neutral[95],
      },
    },
  },
};

export default darkOverrides;
