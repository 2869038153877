import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Paper, Stack } from '@mui/material';

export const Wrapper = styled(Paper)(
  ({ theme }) => css`
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    font-family: ${theme.typography.fontFamily};
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;

    .modal-content {
      background: ${theme.backgroundMode};
      border-radius: 20px;
      width: 852px;
      height: 80%;
      box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    }
  `
);

export const Header = styled(Stack)(
  ({ theme }) => css`
    gap: 5px;
    padding: 20px;
    position: relative;

    .icon {
      font-size: 38px;
    }

    h2 {
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      margin: 0;
    }
    .close-button {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[100] })};
      padding: 5px;
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);

      border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#49454F' })};
      box-shadow: ${theme.validateMode({
        light: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
        dark: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
      })};

      &:hover {
        background: ${theme.validateMode({ dark: 'rgba(255, 255, 255, 0.08)' })};
      }
      .close-icon {
        font-size: 24px;
      }
    }
  `
);

export const ContentWrapper = styled(Stack)(
  ({ theme }) => css`
    height: 100%;
    padding: 0 20px;
    overflow: auto;
  `
);

export const ButtonWrapper = styled(Stack)(
  ({ theme }) => css`
    display: flex;
    gap: 22px;
    justify-content: end;
    padding: 16px 20px;

    .MuiButtonBase-root {
      border-radius: 100px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }

    .action-button {
      background: ${theme.validateMode({ light: theme.colorsCA.darkBlue[50], dark: theme.colorsCA.blue[90] })};
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.blue[10] })};
      height: 48px;
      padding: 10px 20px;

      &:hover {
        background: ${theme.validateMode({ light: theme.colorsCA.darkBlue[40], dark: theme.colorsCA.blue[80] })};
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.blue[10] })};
      }
      &:disabled {
        background: ${theme.validateMode({ light: '#1C1B1F1F', dark: theme.colorsCA.neutral[20] })};
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[50] })};
      }
    }
  `
);
