import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GridColumnsPanel } from '@mui/x-data-grid-pro';

export const TableColumnPanel = styled(GridColumnsPanel)(
  ({ theme }) => css`
    .MuiDataGrid-panelHeader {
      .MuiInputLabel-root {
        &.Mui-focused {
          color: ${theme.validateMode({ light: '#00000099', dark: theme.colorsCA.neutral[95] })};
          transform: translate(0, -1.5px) scale(1);
          font-weight: 400;
        }
        + .MuiInputBase-root {
          margin-top: 22px;
        }
      }
      .MuiInputBase-root {
        &:hover:not(.Mui-disabled, .Mui-error) {
          &::before {
            border-bottom: 1px solid
              ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
          }
        }
        &::after {
          border-width: 1px;
        }
      }
    }
    .MuiDataGrid-panelContent {
      .MuiDataGrid-columnsPanel {
        max-height: 234px;
        padding: 0 0 0 8px;
        margin: 8px 0;
        overflow: auto;
        .MuiDataGrid-columnsPanelRow {
          .MuiSwitch-root {
            .MuiButtonBase-root {
              &.Mui-checked {
                color: ${theme.colorsCA.blue[50]};
                + .MuiSwitch-track {
                  background: ${theme.colorsCA.blue[50]};
                }
              }
            }
          }
          .MuiTypography-root {
            color: ${theme.validateMode({ light: theme.colorsCA.neutral[0], dark: theme.colorsCA.neutral[95] })};
          }
        }
      }
    }
    .MuiDataGrid-panelFooter {
      .MuiButtonBase-root {
        text-transform: uppercase;
        color: ${theme.colorsCA.blue[50]};
        padding: 6px 8px;
      }
    }
  `
);
