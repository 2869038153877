import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const PagePaper = styled(Box)(
  ({ theme }) => css`
    background: ${theme.backgroundMode};
    border-radius: 20px;
    border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#938f9975' })};
    box-shadow: ${theme.shadowCA(4)};
    overflow: hidden;
    height: 100%;
    max-height: 950px;

    margin-bottom: 41px;
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      overflow: auto;
      height: 100%;
    }
  `
);

export const Header = styled(Box)(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#938f9975' })};
    padding: 1rem;
    width: 100%;
  `
);

export const Footer = styled(Box)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#938f9975' })};
    padding: 1rem;
    width: 100%;
  `
);
