import { API, ConnexDesignSystem } from 'connex-cds';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import messages from './i18n';
import { InitializeApi } from './api/InitializeApi';
import menuConfig from './menu-config';
import ThemeProvider from './themes/ThemeProvider';
import { AppRoutes } from './AppRoutes';
import { GlobalStoreProvider } from './stores';
import { ModalProvider } from './hooks/useModal';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

API.setSource('dexa');

const ONE_HOUR = 1000 * 60 * 60;
const TWELVE_HOURS = ONE_HOUR * 12;

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000, cacheTime: TWELVE_HOURS, refetchOnWindowFocus: false } },
});

const App = () => {
  return (
    <InitializeApi>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ConnexDesignSystem
            messages={messages}
            initialTimeZone="America/Chicago"
            appId="dexa"
            menuConfig={menuConfig}
            contentOnly={true}
          >
            <ThemeProvider>
              <GlobalStoreProvider>
                <ModalProvider>
                  <AppRoutes />
                </ModalProvider>
              </GlobalStoreProvider>
            </ThemeProvider>
          </ConnexDesignSystem>
        </QueryClientProvider>
      </BrowserRouter>
    </InitializeApi>
  );
};

export default App;
