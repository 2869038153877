import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button as MUIButton } from '@mui/material';

const pressedStyle = ({ theme }) => css`
  background: ${theme.validateMode({ light: theme.colorsCA.blue[95], dark: theme.colorsCA.blue[90] })};
  color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.darkBlue[10] })};
  box-shadow: ${theme.validateMode({
    light: theme.shadowCA(1),
    dark: theme.shadowCA(3),
  })};
`;

const defaultActiveStyle = ({ theme }) => css`
  &.MuiButtonBase-root {
    background: ${theme.validateMode({ light: theme.colorsCA.blue[95], dark: theme.colorsCA.darkBlue[60] })};
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.blue[95] })};
  }

  &.Mui-disabled {
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
    pointer-events: none;
  }

  &:hover {
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[95], dark: theme.colorsCA.darkBlue[70] })};
  }
`;

export const Button = styled(MUIButton, {
  shouldForwardProp: prop => prop !== 'pressed' && prop !== 'defaultActive',
})(
  ({ theme, defaultActive, pressed }) => css`
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: center;
    align-items: center;
    line-height: 20px;
    font-size: 20px;
    border-radius: 100px;
    border: 1px solid ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.darkBlue[10] })};
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[10] })};
    padding: 10px 24px;
    font-weight: 500;
    box-sizing: border-box;
    height: 40px;

    &:hover {
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[95], dark: theme.surfaceCA(2) })};
      border: 1px solid ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.darkBlue[10] })};
    }

    ${defaultActive ? defaultActiveStyle({ theme }) : ''}

    ${pressed ? pressedStyle({ theme }) : ''}
  `
);
