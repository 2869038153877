import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';

import { useTracesStore } from './traces.store';

const INITIAL_STATE = {
  selectedTraces: [],
  selectedTracesCrn: [],
  countSelectedTraces: 0,
  addTrace: () => {},
  clearTraces: () => {},
};

const TracesInUseStoreContext = createContext(INITIAL_STATE);

const SELECTED_TRACES_REDUCER_ACTIONS = {
  REPLACE_TRACES: 'REPLACE_TRACES',
  RESET_TRACES: 'RESET_TRACES',
};

const selectedTracesReducer = (state, action) => {
  switch (action.type) {
    case SELECTED_TRACES_REDUCER_ACTIONS.REPLACE_TRACES: {
      return action.payload;
    }

    case SELECTED_TRACES_REDUCER_ACTIONS.RESET_TRACES: {
      return [];
    }

    default:
      return state;
  }
};

const TracesInUseStoreProvider = ({ children }) => {
  const { traces: allTraces } = useTracesStore();

  const [selectedTraces, selectedTracesDispatch] = useReducer(selectedTracesReducer, []);

  const selectedTracesCrn = useMemo(() => {
    return selectedTraces.map(trace => trace.crn);
  }, [selectedTraces]);

  const countSelectedTraces = useMemo(() => {
    return selectedTracesCrn.length;
  }, [selectedTracesCrn]);

  const replaceTracesCrn = useCallback(
    (incomingTracesCrn = []) => {
      const newSelectedBillabes = incomingTracesCrn.map(incomingCrn => allTraces.find(trace => trace.crn === incomingCrn));

      selectedTracesDispatch({
        type: SELECTED_TRACES_REDUCER_ACTIONS.REPLACE_TRACES,
        payload: newSelectedBillabes,
      });
    },
    [allTraces]
  );

  const clearTraces = () => selectedTracesDispatch({ type: SELECTED_TRACES_REDUCER_ACTIONS.RESET_TRACES });

  return (
    <TracesInUseStoreContext.Provider
      value={{
        replaceTracesCrn,
        selectedTraces,
        selectedTracesCrn,
        clearTraces,
        countSelectedTraces,
      }}
    >
      {children}
    </TracesInUseStoreContext.Provider>
  );
};

const useTracesInUseStore = () => {
  const { replaceTracesCrn, selectedTraces, selectedTracesCrn, clearTraces, countSelectedTraces } =
    useContext(TracesInUseStoreContext);

  return {
    replaceTracesInUseCrn: replaceTracesCrn,
    tracesInUse: selectedTraces,
    tracesInUseCrn: selectedTracesCrn,
    clearTracesInUse: clearTraces,
    countTracesInUse: countSelectedTraces,
  };
};

export { TracesInUseStoreContext, TracesInUseStoreProvider, useTracesInUseStore };
