import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Popper as MUIPopper, DialogActions } from '@mui/material';

export const Popper = styled(MUIPopper)(
  ({ theme }) => css`
    margin-bottom: 20px !important;
    z-index: 20;

    .MuiPaper-root {
      background: ${theme.backgroundMode};
      border-radius: 20px;
      border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#49454F' })};
    }

    @media screen and (max-width: 1280px) {
      .MuiPickersArrowSwitcher-root {
        padding: 0;
      }

      .MuiPickersSlideTransition-root {
        min-height: 204px;
      }
    }
  `
);

export const ActionBar = styled(DialogActions)(
  ({ theme }) => css`
    border-top: 1px solid #e1e1e1;

    .MuiButtonBase-root {
      background: ${theme.colorsCA.darkBlue[50]};
      border-radius: 100px;
      color: ${theme.colorsCA.neutral[100]};

      &:hover {
        background: ${theme.colorsCA.darkBlue[50]};
      }

      &:disabled {
        background: ${theme.validateMode({ light: '#1C1B1F1F', dark: theme.colorsCA.neutral[20] })};
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[50] })};
      }
    }

    @media screen and (max-width: 1280px) {
      .MuiButtonBase-root {
        height: 20px;
      }
    }
  `
);
