import { useMemo } from 'react';
import { Localization } from 'connex-cds';
import { omit } from 'lodash';
import { LINEITEMS_DATAGRID_COLUMNKEYS } from './TraceEventsDataGrid.constants.js';
import { TraceEventDetails } from './TraceEventDetails.js';

const CURRENCY = 'USD';

export const LINEITEMS_DATAGRID_COLUMNS = {
  [LINEITEMS_DATAGRID_COLUMNKEYS.NAME]: {
    field: 'name',
    headerNameI18n: 'gridDetailHeader_eventName',
    watchEditingStatusFields: ['name'],
    minWidth: 300,
    valueGetter: ({ row }) => row.mutated.name,
  },
  [LINEITEMS_DATAGRID_COLUMNKEYS.TRACE_LEVEL_ID]: {
    field: 'traceLevelId',
    headerNameI18n: 'gridDetailHeader_traceLevelId',
    minWidth: 150,
    valueGetter: ({ row }) => row.mutated.traceLevelId,
  },
  [LINEITEMS_DATAGRID_COLUMNKEYS.TIMESTAMP]: {
    field: 'timestamp',
    headerNameI18n: 'gridDetailHeader_timestamp',
    minWidth: 300,
    valueGetter: ({ row }) => row.mutated.timestamp,
  },
  [LINEITEMS_DATAGRID_COLUMNKEYS.DETAILS]: {
    field: 'details',
    headerNameI18n: 'gridDetailHeader_details',
    minWidth: 800,
    valueGetter: ({ row }) => {
      return JSON.stringify(omit(row.mutated, ['name', 'traceLevelId', 'timestamp']), null, 2);
    },
    renderCell: TraceEventDetails,
  },
};

export const useDataGridColumns = columnKeys => {
  const translateMessage = Localization.useTranslateMessage();

  const gridColumns = useMemo(() => {
    const defaultConfigKeys = Object.keys(LINEITEMS_DATAGRID_COLUMNS);

    const columnsConfig = defaultConfigKeys.flatMap(configKey => {
      if (!columnKeys.some(colKey => configKey === colKey)) {
        return [];
      }

      const { headerNameI18n, ...columnConfig } = omit(LINEITEMS_DATAGRID_COLUMNS[configKey], [
        'watchEditingStatusFields',
      ]);

      return [
        {
          ...columnConfig,
          headerName: translateMessage(headerNameI18n),
        },
      ];
    });

    const fieldsToWatch = defaultConfigKeys.flatMap(configKey => {
      if (!columnKeys.some(colKey => configKey === colKey)) {
        return [];
      }

      const { watchEditingStatusFields } = LINEITEMS_DATAGRID_COLUMNS[configKey];

      return watchEditingStatusFields || [];
    });

    return { columnsConfig, fieldsToWatch };
  }, [columnKeys, translateMessage]);

  return gridColumns;
};
