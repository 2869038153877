import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Box } from '@mui/material';

export const HeaderContainer = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    transition: height 0.3s ease;
    .header {
      display: flex;
      padding: 20px 20px 20px 10px;
      color: var(--color-text-page); //#605d62; //

      .left {
        .feature-name {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 600;
          font-size: clamp(1.25rem, 0.625rem + 2.5vw, 2.5rem);
          font-style: normal;
          line-height: normal;
        }

        .feature-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .feature-icon {
          width: 36px;
          height: 36px;
        }
      }

      & > .right {
        flex: 1;
        text-align: right;
        .center-aligned {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 20px;
          .mobile-menu-icon {
            display: none;
          }
        }

        .chat-icon-container {
          position: relative;

          .chat-icon-badge {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 0px;
            right: 0px;
            font-family: 'Work Sans', serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            text-align: center;
            padding: 1px;
            border-radius: 50%;
            border: 2px solid #fafafa;
            background: ${theme.colorsCA.red[30]};
            color: ${theme.colorsCA.neutral[100]};

            &.max-unread-messages {
              font-size: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    &.is-mobile {
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100vw;
      background: ${theme.validateMode({
        light: theme.colorsCA.neutral[100],
        dark: 'linear-gradient(0deg, rgba(208, 188, 255, 0.12) 0%, rgba(208, 188, 255, 0.12) 100%), #1C1B1F',
      })} !important;
      border-bottom: 1px solid var(--Surfaces-Dark-sys-light-outline-variant, #cac4d0);
      box-shadow: 0px 2px 4px 0px rgba(101, 119, 136, 0.2);
      .header {
        flex-direction: column-reverse;
        padding: 16px 24px;
        .left {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
          padding-top: 0.875rem;
          padding-left: 0.5rem;
          .arrow-back-icon {
            cursor: pointer;
          }
          .feature-name {
            gap: 4px;
            font-size: clamp(1.25rem, 0.625rem + 2.5vw, 2rem);
          }

          .feature-icon {
            width: 32px;
            height: 32px;
          }

          .progress-bar {
            margin-left: 8px;
            background-color: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[95],
            })};
            .MuiLinearProgress-bar1Determinate {
              background-color: ${theme.validateMode({
                light: theme.colorsCA.darkBlue[50],
              })};
            }
          }
        }
        .entity-picker {
          display: none;
        }
        .header-divider {
          height: 1px;
          background-color: #cac4d0;
          border: none;
          margin: 0 -30px 0 -30px;
          width: calc(100% + 60px);
        }
        & > .right {
          flex: 1;
          padding-bottom: 16px;
          .center-aligned {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .mobile-menu-icon {
              display: flex;
            }
          }
        }
      }

      @media screen and (max-width: 768px) {
        .header {
          padding: 0.875rem 0.625rem 0.625rem 0.625rem;
          .left {
            gap: 0.25rem;
            padding-top: 0.75rem;
            .arrow-back-icon {
              width: 22px;
              height: 22px;
            }
            .feature-name {
              gap: 2px;
              max-width: 60%;
            }
            .feature-icon {
              width: 28px;
              height: 28px;
            }
            .action-buttons {
              .MuiIconButton-root {
                height: 36px;
                width: 36px;
              }
            }
          }
          .header-divider {
            margin: 0 -0.625rem 0 -0.625rem;
            width: calc(100% + 1.25rem);
          }
          & > .right {
            padding-bottom: 10px;
            .center-aligned {
            }
          }
        }
      }
      @media screen and (max-width: 430px) {
        .header {
          .left {
            .feature-icon {
              width: 24px;
              height: 24px;
            }
            .feature-name {
              max-width: 70%;
            }
            .progress-bar {
              margin-left: 8px;
            }
            .collapse-search-button {
              padding: 4px;
            }
          }
        }
      }
      &.disable-mobile-sidebar {
        border-bottom: unset;
        box-shadow: unset;
        @media screen and (max-width: 1200px) {
          height: unset;
          .header {
            & > .right {
              .center-aligned {
                justify-content: end;
              }
            }
          }
        }
      }
    }
  `}
`;
