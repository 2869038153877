import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as S from './style';

const Icon = ({ type, className: classNameProp = '', ...props }) => {
  const IconComponent = S.Icon(type);
  return <IconComponent className={cn('icon', classNameProp)} {...props} />;
};

Icon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export { Icon };
