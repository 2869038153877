import { useMemo } from 'react';
import { Localization } from 'connex-cds';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { Icon, Button } from 'core';
import * as S from './style';
const { useTranslateMessage } = Localization;

const DetailActionButtons = ({ countForDelete, countForEdit, onDiscardChanges, onSaveChanges, isLoading }) => {
  const translateMessage = useTranslateMessage();

  const showButtons = useMemo(() => {
    return countForDelete + countForEdit !== 0;
  }, [countForDelete, countForEdit]);

  if (!showButtons) {
    return null;
  }

  return (
    <S.InfoWrapper className="changes-info-wrapper">
      <Stack className="captions-wrapper">
        {countForDelete > 0 ? (
          <Stack className="caption" direction="row" alignItems="center">
            <Icon className="deleted" type="circleExclamation" />
            <span>{`${countForDelete} ${translateMessage('gridDetailCaption_delete_label', {
              value: countForDelete,
            })}`}</span>
          </Stack>
        ) : null}
        {countForEdit > 0 ? (
          <Stack className="caption" direction="row" alignItems="center">
            <Icon className="edited" type="circleExclamation" />
            <span>{`${countForEdit} ${translateMessage('gridDetailCaption_edit_label', {
              value: countForEdit,
            })}`}</span>
          </Stack>
        ) : null}
      </Stack>
      <Stack direction="row" className="buttons-wrapper">
        <Button className="cancel-button" variant="text" onClick={onDiscardChanges} disabled={isLoading}>
          {translateMessage('cancel')}
        </Button>
        <Button className="save-button" variant="text" onClick={onSaveChanges} disabled={isLoading}>
          {translateMessage('save')}
        </Button>
      </Stack>
    </S.InfoWrapper>
  );
};

DetailActionButtons.propTypes = {
  countForDelete: PropTypes.number,
  countForEdit: PropTypes.number,
  onDiscardChanges: PropTypes.func,
  onSaveChanges: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DetailActionButtons;
