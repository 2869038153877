import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTraceEventsTableContext } from './contexts';
import * as S from './style';

const INITIAL_SORT_MODEL = [{ field: 'timestamp' }];

const LineItemsDataGrid = ({ columns }) => {
  const { rows } = useTraceEventsTableContext();

  return (
    <S.LineItemsDataGrid
      sortModel={INITIAL_SORT_MODEL}
      disableRowSelectionOnClick
      disableMultipleRowSelection
      disableColumnReorder
      disableColumnMenu
      hideFooter
      rowHeight={50}
      rows={rows}
      columns={columns}
      getRowId={row => row.key}
      getRowClassName={({ row }) =>
        cn({
          'marked-to-create': row.editingStatus.isMarkedToCreate,
          'marked-to-delete': row.editingStatus.isMarkedToDelete,
        })
      }
      getRowHeight={() => 'auto'}
    />
  );
};

LineItemsDataGrid.propTypes = {
  columns: PropTypes.array,
};

export { LineItemsDataGrid };
