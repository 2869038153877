import { Localization } from 'connex-cds';
import PropTypes from 'prop-types';

import { Icon } from 'core';
import * as S from '../style';

const DefaultTrigger = ({ translationId, onClick, disabled, startIcon, isOpen }) => {
  const translateMessage = Localization.useTranslateMessage();
  return (
    <S.DefaultTrigger
      variant="outlined"
      onClick={onClick}
      startIcon={startIcon}
      endIcon={<Icon type={isOpen ? 'arrowUp' : 'arrowDown'} />}
      pressed={isOpen}
      defaultActive
      disabled={disabled}
    >
      {translateMessage(translationId)}
    </S.DefaultTrigger>
  );
};

DefaultTrigger.propTypes = {
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.element,
  translationId: PropTypes.string,
};

export { DefaultTrigger };
