import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)(
  ({ theme }) => css`
    position: relative;
    .number-input-width {
      display: block;
      color: transparent;
      text-align: right;
    }
  `
);

export const Input = styled('input')(
  ({ theme }) => css`
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    text-align: right;
  `
);
