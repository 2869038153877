import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Drawer, Stack } from '@mui/material';

export const ConfigPanel = styled(Drawer)(
  ({ theme }) => css`
    .MuiPaper-root {
      width: 378px;
      border-radius: 10px 0 0 10px;
      border-left: 1px solid ${theme.validateMode({ light: '#CAC4D0', dark: '#938F99' })};
      background: ${theme.backgroundMode};
    }
  `
);

export const Header = styled(Stack)(
  ({ theme }) => css`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border-bottom: 1px solid ${theme.validateMode({ light: '#e1e1e1', dark: '#938F99' })};
    padding: 21px 10px;
    .MuiTypography-root {
      font-size: 22px;
      font-weight: 500;
    }
  `
);

export const Content = styled(Stack)(
  ({ theme }) => css`
    flex-grow: 1;
    padding: 0px 24px;
    .MuiBox-root {
      padding: 20px 0;
      border-bottom: 1px solid ${theme.validateMode({ light: theme.colorsCA.neutral[90], dark: '#938F99' })};
      &:last-child {
        border-bottom: none;
      }
    }
    .MuiFormControlLabel-root {
      gap: 30px;
      margin: 0;
      .MuiTypography-root {
        font-size: 20px;
      }
    }
    .MuiSwitch-root {
      width: auto;
      height: 40px;
      padding: 0px;
      .MuiButtonBase-root {
        padding: 5.3px;
        &:hover {
          background: none;
        }
        &.Mui-checked {
          transform: translateX(-41px);
          left: 100%;
          + .MuiSwitch-track {
            opacity: 1;
            background: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[10],
              dark: theme.colorsCA.darkBlue[60],
            })};
          }
        }
      }
      .MuiSwitch-track {
        opacity: 1;
        border-radius: 57px;
        background: ${theme.validateMode({
          light: 'rgba(217, 217, 217, 0.75)',
          dark: 'rgba(28, 27, 31, 0.75)',
        })};
        width: 78px;
      }
      .MuiSwitch-thumb {
        width: 30px;
        box-shadow: none;
        height: 30px;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
        background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #f9f9f9 100%);
      }
    }
  `
);

export const Footer = styled(Stack)(
  ({ theme }) => css`
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 22px;
    border-top: 1px solid ${theme.validateMode({ light: '#e1e1e1', dark: '#938F99' })};
    padding: 13px 17px;
    .panel-button {
      border-radius: 100px;
      padding: 14px 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      &.cancel-button {
        background: ${theme.validateMode({ dark: theme.colorsCA.neutral[10] })};
        border: 1px solid ${theme.validateMode({ light: '#79747e', dark: '#938F99' })};
        color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[10], dark: theme.colorsCA.blue[80] })};
      }
      &.save-button {
        background: ${theme.validateMode({ light: theme.colorsCA.blue[10], dark: theme.colorsCA.blue[90] })};
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.blue[10] })};
      }
      &.Mui-disabled {
        background: ${theme.validateMode({ light: 'rgba(28, 27, 31, 0.12)', dark: theme.colorsCA.neutral[20] })};
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[50] })};
      }
    }
  `
);
