import React from 'react';
import { Localization, Theme } from 'connex-cds';
import * as S from './style';
import { ReactComponent as EmptySearchLight } from 'assets/images/search_empty_light.svg';
import { ReactComponent as EmptySearchDark } from 'assets/images/search_empty_dark.svg';

const emptySearchMode = {
  light: EmptySearchLight,
  dark: EmptySearchDark,
};

const EmptyState = () => {
  const translateMessage = Localization.useTranslateMessage();
  const { themeName } = Theme.useThemeContext();
  const EmptySearchImage = emptySearchMode[themeName];
  return (
    <S.EmptySearch>
      <EmptySearchImage />
      <p className="emptyState-highlight">{translateMessage('emptyState_highlight')} </p>
      <p>{translateMessage('emptyState_text')} </p>
    </S.EmptySearch>
  );
};

export { EmptyState };
