import { useMemo } from 'react';
import cn from 'classnames';
import { Localization } from 'connex-cds';
import { Icon } from 'core';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { IconButton } from '@mui/material';

export const TABLE_CLASSNAMES = {
  row: 'main-grid-row',
};

const UNHIDEABLE_COLUMNS = ['__check__', '__detail_panel_toggle__'];

export const getTogglableColumns = columns =>
  columns.filter(column => !UNHIDEABLE_COLUMNS.includes(column.field)).map(column => column.field);

export const useTableLocaleText = () => {
  const translateMessage = Localization.useTranslateMessage();

  const localeText = useMemo(
    () => ({
      columnMenuLabel: translateMessage('gridHeaderMenu_menu_label'),
      columnMenuUnsort: translateMessage('gridHeaderMenu_unsort_label'),
      columnMenuSortAsc: translateMessage('gridHeaderMenu_asc_label'),
      columnMenuSortDesc: translateMessage('gridHeaderMenu_desc_label'),
      columnHeaderSortIconLabel: translateMessage('gridHeaderMenu_sort_label'),
      columnMenuHideColumn: translateMessage('gridHeaderMenu_hide_column_label'),
      columnMenuManageColumns: translateMessage('gridHeaderMenu_manage_columns_label'),
      columnsPanelTextFieldLabel: translateMessage('gridHeaderMenu_find_column_label'),
      columnsPanelTextFieldPlaceholder: translateMessage('gridHeaderMenu_search_column_label'),
      columnsPanelShowAllButton: translateMessage('gridHeaderMenu_show_all_label'),
      columnsPanelHideAllButton: translateMessage('gridHeaderMenu_hide_all_label'),
      toolbarColumns: translateMessage('gridHeaderMenu_show_columns_label'),
    }),
    [translateMessage]
  );

  return localeText;
};

export const useTableColumns = () => {
  const translateMessage = Localization.useTranslateMessage();
  const columns = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 40,
        minWidth: 40,
      },
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        width: 40,
        minWidth: 40,
        renderCell: params => (
          <IconButton className="detail-toggler-button" disabled={!params.row.events}>
            <Icon className="expand" type={!!params.value ? 'expandLess' : 'expandMore'} />
          </IconButton>
        ),
      },
      {
        field: 'id',
        headerName: translateMessage('general_tableHeader_id'),
        flex: 1,
        headerAlign: 'right',
        align: 'right',
        // renderCell: params => <Localization.ShortDate date={new Date(params.value)} />,
      },
      {
        field: 'traceTypeId',
        headerName: translateMessage('trace_tableHeader_traceTypeId'),
        flex: 1,
        headerAlign: 'right',
        align: 'right',
      },
      {
        field: 'traceLevelId',
        headerName: translateMessage('trace_tableHeader_traceLevelId'),
        flex: 1,
        headerAlign: 'right',
        align: 'right',
      },
      {
        field: 'sourceSystemId',
        headerName: translateMessage('trace_tableHeader_sourceSystemId'),
        flex: 1,
      },
      {
        field: 'sourceSystemTypeId',
        headerName: translateMessage('trace_tableHeader_sourceSystemTypeId'),
        flex: 1,
      },
      {
        field: 'objectId',
        headerName: translateMessage('trace_tableHeader_objectId'),
        flex: 1,
      },
      {
        field: 'objectTypeId',
        headerName: translateMessage('trace_tableHeader_objectTypeId'),
        flex: 1,
      },
      {
        field: 'modifyDate',
        headerName: translateMessage('trace_tableHeader_modifyDate'),
        flex: 1,
        renderCell: params => <Localization.ShortDateTime date={new Date(params.value)} />,
      },
    ],
    [translateMessage]
  );

  return columns;
};
