import { API, Core } from 'connex-cds';
import React from 'react';

export const InitializeApi = ({ children }) => {
  const [isConfiguring, setIsConfiguring] = React.useState(true);

  React.useEffect(() => {
    API.initialize().finally(() => {
      setIsConfiguring(false);
    });
  }, []);

  // Prevents children from rendering during service discovery.
  return <Core.Spinner spin={isConfiguring}>{children}</Core.Spinner>;
};
