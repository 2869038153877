import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Stack } from '@mui/material';

export const RowDetail = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    padding: 0px 20px 40px;
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
    &.selected {
      background: ${theme.validateMode({
        light: theme.colorsCA.blue[95],
        dark: 'linear-gradient(0deg, rgba(169, 229, 255, 0.31) 0%, rgba(169, 229, 255, 0.31) 100%), #434247)',
      })};
      &.voided {
        background: ${theme.validateMode({ light: '#FFF6F7', dark: '#986977' })};
      }
    }

    &.voided {
      background: ${theme.validateMode({ light: '#FFF6F7', dark: '#492532' })};
    }

    .inner-detail-container {
      width: 100%;
      flex-direction: row;
      gap: 40px;

      @media screen and (max-width: 2000px) {
        flex-direction: column;
        align-items: end;
      }
    }

    .grid-wrapper {
      width: 90%;
      @media screen and (max-width: 2000px) {
        width: 100%;
      }
    }
  `
);

export const InfoWrapper = styled(Stack)(
  ({ theme }) => css`
    padding: 50px 0 0;
    gap: 25px;
    @media screen and (max-width: 1500px) {
      padding: 0;
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    .captions-wrapper {
      gap: 25px;
      @media screen and (max-width: 1500px) {
        gap: 12px;
      }
      .caption {
        gap: 10px;
        .icon {
          &.deleted {
            color: ${theme.colorsCA.red[50]};
          }
          &.edited {
            color: ${theme.colorsCA.orange[40]};
          }
        }

        span {
          font-size: 20px;
          font-weight: 500;
          letter-spacing: -0.5px;
        }
      }
    }

    .buttons-wrapper {
      gap: 22px;
      @media screen and (max-width: 1500px) {
        justify-content: end;
      }
      .MuiButtonBase-root {
        border-radius: 100px;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        height: 40px;
        padding: 6px 20px;
        line-height: 20px;
        text-align: center;

        &.cancel-button {
          background: ${theme.validateMode({ dark: theme.colorsCA.neutral[10] })};
          border: 1px solid #79747e;
          color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[10], dark: theme.colorsCA.blue[80] })};
        }

        &.save-button {
          background: ${theme.validateMode({ light: theme.colorsCA.darkBlue[50], dark: theme.colorsCA.blue[90] })};
          color: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.blue[10] })};

          &:hover {
            background: ${theme.validateMode({ light: theme.colorsCA.darkBlue[50], dark: theme.colorsCA.blue[90] })};
            color: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.blue[10] })};
          }
        }
      }
    }
  `
);
