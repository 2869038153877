import React from 'react';
import { useIntl } from 'react-intl';
import { useShortDate } from './ShortDate';
import { useTime } from './Time';

const shortDateTime = ({ date, hideTimeZone = false, withDayOfWeek = false, getShortDate, getTime }) => {
  const shortDate = getShortDate(date, withDayOfWeek);
  const time = getTime(date, hideTimeZone);

  return `${shortDate} ${time}`;
};

export const ShortDateTime = ({ date, hideTimeZone = false, withDayOfWeek = false }) => {
  const { getShortDate } = useShortDate();
  const { getTime } = useTime();

  return shortDateTime({ date, hideTimeZone, withDayOfWeek, getShortDate, getTime });
};

export const useShortDateTime = ({ overrideTimeZone = undefined } = {}) => {
  const { getShortDate } = useShortDate({ overrideTimeZone });
  const { getTime } = useTime({ overrideTimeZone });
  const { formatDate } = useIntl();

  const getShortDateTime = React.useCallback(
    (date, withDayOfWeek = false, hideTimeZone = false) => {
      return shortDateTime({ date, formatDate, withDayOfWeek, hideTimeZone, getShortDate, getTime });
    },
    [formatDate, getShortDate, getTime]
  );

  return { getShortDateTime, ShortDateTime };
};
