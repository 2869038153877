import { useMemo } from 'react';
import { Localization } from 'connex-cds';

import { Icon } from '../index';
import { ActionBar } from './components';
import * as S from './style';

export const useLocaleText = () => {
  const translateMessage = Localization.useTranslateMessage();
  const localeText = useMemo(
    () => ({ start: translateMessage('datePicker_start'), end: translateMessage('datePicker_end') }),
    [translateMessage]
  );

  return localeText;
};

export const RANGE_PICKER_SLOTS = {
  actionBar: ActionBar,
  popper: S.Popper,
};

export const RANGE_PICKER_SLOT_PROPS = {
  textField: { InputProps: { startAdornment: <Icon type="calendar" /> } },
};
