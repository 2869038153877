import { useState } from 'react';
import { Localization } from 'connex-cds';
import { DateTimeRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { supportedLocales } from 'i18n/common';
import { checkNonNullInArray } from 'utils';

import { SHOULD_DISABLE_DATE_VALIDATIONS } from './constants';
import { RANGE_PICKER_SLOTS, RANGE_PICKER_SLOT_PROPS, useLocaleText } from './config';

const RangePicker = ({ initialOpen, onChange, onAccept, value }) => {
  const { userLocale } = Localization.useLocalizationContext();

  const [open, setOpen] = useState(initialOpen);
  const [pickerErrors, setPickerErrors] = useState([null, null]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const localeText = useLocaleText();

  const handleShouldDisableDate = (date, datePosition) => {
    if (open) {
      return false;
    }

    const invalidStartDate = SHOULD_DISABLE_DATE_VALIDATIONS.startDateBeforeEndDate({
      date,
      datePosition,
      currentErrors: pickerErrors,
      currentDates: value,
    });

    if (invalidStartDate) {
      return true;
    }

    const exceeds31Days = SHOULD_DISABLE_DATE_VALIDATIONS.max31Days({
      date,
      datePosition,
      currentErrors: pickerErrors,
      currentDates: value,
    });

    if (exceeds31Days) {
      return true;
    }
  };

  const handleAccept = async () => await Promise.resolve(onAccept?.());

  const locale = supportedLocales?.[userLocale];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale?.name}>
      <MuiDateRangePicker
        format={
          locale?.formats?.L && locale?.formats?.LT
            ? `${locale?.formats?.L} ${locale?.formats?.LT}`
            : 'MM/DD/YYYY hh:mm:ss A'
        }
        localeText={localeText}
        onClose={handleClose}
        closeOnSelect={false}
        onError={errors => setPickerErrors(errors)}
        shouldDisableDate={handleShouldDisableDate}
        onOpen={handleOpen}
        onAccept={handleAccept}
        open={open}
        onChange={onChange}
        maxDate={dayjs()}
        calendars={2}
        slots={RANGE_PICKER_SLOTS}
        slotProps={{
          ...RANGE_PICKER_SLOT_PROPS,
          actionBar: {
            disabled: !checkNonNullInArray(value),
          },
        }}
        value={value}
      />
    </LocalizationProvider>
  );
};

RangePicker.propTypes = {
  initialOpen: PropTypes.bool,
  onChange: PropTypes.func,
  onAccept: PropTypes.func,
  value: PropTypes.array,
};

export { RangePicker };
