import { css } from '@emotion/react';
import styled from '@emotion/styled';

const ObjPropertyName = styled('span')(
  ({ theme }) => css`
    color: ${theme.validateMode({ light: '#44f', dark: '#99f' })};
  `
);

const ObjPropertyValue = styled('span')(
  ({ theme }) => css`
    color: ${theme.validateMode({ light: '#f44', dark: '#f99' })};
  `
);

const ObjPropertyNumericValue = styled('span')(
  ({ theme }) => css`
    color: ${theme.validateMode({ light: '#499', dark: '#9ff' })};
  `
);

const TraceEventDetails = ({ value }) => {

  const renderValue = (value, recursionLevel = 0) => {
    if (value === null) {
      return 'null';
    }
    if (value === undefined) {
      return 'undefined';
    }
    switch (typeof value) {
      case 'object':
        return renderObj(value, recursionLevel);
      case 'array':
        return renderArray(value, recursionLevel);
      case 'string':
        return <ObjPropertyValue>{`"${value}"`}</ObjPropertyValue>;
      case 'number':
        return <ObjPropertyNumericValue>{value.toString()}</ObjPropertyNumericValue>;
      default:
        return value.toString();
    }
  };

  const renderArray = (arr, recursionLevel = 0) => {
    if (!arr) {
      return null;
    }
    const open = '[';
    const close = ']';
    const padding = [];
    for (let i = 0; i < recursionLevel; i++) {
      padding.push(<>&nbsp;&nbsp;</>);
    }
    return (
      <span>
        {open}
        <br />
        {arr.map((item, index) => {
          return (
            <span key={index}>
              {padding}&nbsp;&nbsp;
              {renderValue(item, recursionLevel + 1)},
              <br />
            </span>
          );
        })}
        {padding}
        {close}
      </span>
    );
  };

  const renderObj = (obj, recursionLevel = 0) => {
    if (!obj) {
      return null;
    }
    if (Array.isArray(obj)) {
      return renderArray(obj, recursionLevel);
    }
    const open = '{';
    const close = '}';
    const padding = [];
    for (let i = 0; i < recursionLevel; i++) {
      padding.push(<>&nbsp;&nbsp;</>);
    }
    return (
      <span>
        {open}
        <br />
        {Object.keys(obj).map((key, index) => {
          return (
            <span key={index}>
              {padding}&nbsp;&nbsp;
              <ObjPropertyName>"{key}"</ObjPropertyName>: {renderValue(obj[key], recursionLevel + 1)},
              <br />
            </span>
          );
        })}
        {padding}
        {close}
      </span>
    );
  };

  const message = JSON.parse(value);
  return renderObj(message);
};

export { TraceEventDetails };
