import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const SkeletonWrapper = styled(Stack)(
  ({ theme }) => css`
    .skeleton-cell {
      padding: 0px 28px;
      display: flex;
      align-items: center;
      height: 54px;
      .MuiSkeleton-root {
        width: 100%;
        height: 16px;
      }
    }
  `
);
