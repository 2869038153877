import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Localization } from 'connex-cds';
import { Icon } from 'core';
import * as S from './style';

const FilterButton = ({ isActive, translationId, type, isDropdown, disabled, onClick, isPressed }) => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <S.Button className={cn({ active: isActive })} disabled={!isActive && disabled} onClick={onClick} size="small">
      <Icon className="icon" type={type} />
      {translateMessage(translationId)}
      {isDropdown ? <Icon type={isPressed ? 'arrowUp' : 'arrowDown'} /> : null}
    </S.Button>
  );
};

FilterButton.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isDropdown: PropTypes.bool,
  isPressed: PropTypes.bool,
  onClick: PropTypes.func,
  translationId: PropTypes.string,
  type: PropTypes.string,
};

export default FilterButton;
