import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Tabs as MUITabs, Stack } from '@mui/material';

export const HomeWrapper = styled(Stack)(
  ({ theme }) => css`
    gap: 25px;
    height: 100%;
    // margin-top: -50px;

    .tabs-container {
      flex-direction: row;
      gap: 39px;
    }

    .tabs-content {
      height: calc(100% - 74px);
      .filter-container {
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        // TODO remove filter-invoice class when filters are added
        &.filter-invoice {
          justify-content: flex-end;
        }
        .extra-filters {
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 22px;
          .settings-button {
            color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
          }
        }
      }
      .content-container {
        // height: calc(100% - 80px);
        margin: 20px 0;
      }
    }
  `
);

export const Tabs = styled(MUITabs)(
  ({ theme }) => css`
    .MuiTabs-flexContainer {
      width: fit-content;
      border-bottom: 1px solid ${theme.validateMode({ light: '#e7e0ec', dark: '#79747E' })};
      .MuiButtonBase-root {
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.1px;
        text-transform: capitalize;

        &.Mui-selected {
          color: ${theme.validateMode({ light: theme.colorsCA.blue[60], dark: theme.colorsCA.blue[90] })};
        }
      }
    }
    .MuiTabs-indicator {
      background: ${theme.validateMode({ light: theme.colorsCA.blue[60], dark: theme.colorsCA.blue[90] })};
      border-radius: 10px 10px 0 0;
    }
  `
);
