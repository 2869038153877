import { css } from '@emotion/react';

const GlobalStyles = ({ theme }) => css`
  * {
    scrollbar-color: ${theme.validateMode({ light: '#a6a7a9', dark: theme.colorsCA.darkBlue[40] })}
      ${theme.validateMode({ light: '#e7e7e7', dark: theme.colorsCA.darkBlue[70] })};
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${theme.validateMode({ light: '#e7e7e7', dark: theme.colorsCA.darkBlue[70] })};
      border-radius: 8px;
      border: none;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.validateMode({ light: '#a6a7a9', dark: theme.colorsCA.darkBlue[40] })};
      border-radius: 8px;
    }
  }
`;

export default GlobalStyles;
