import { pick } from 'lodash';

export const REDUCER_ACTIONS = {
  INITIALIZE: 'INITIALIZE',
  ADD_ITEMS: 'ADD_ITEMS',
  UPDATE_AMOUNT: 'UPDATE_AMOUNT',
  UPDATE_AFTER_PRODUCTS_LIST_LOADS: 'UPDATE_AFTER_PRODUCTS_LIST_LOADS',
  UPDATE_AFTER_UOMS_LIST_LOADS: 'UPDATE_AFTER_UOMS_LIST_LOADS',
  UPDATE_PRODUCT_VALUE: 'UPDATE_PRODUCT_VALUE',
  UPDATE_PRODUCT_INPUT: 'UPDATE_PRODUCT_INPUT',
  UPDATE_UOM_VALUE: 'UPDATE_UOM_VALUE',
  UPDATE_UOM_INPUT: 'UPDATE_UOM_INPUT',
  DELETE_ITEM: 'DELETE_ITEM',
};

export const REDUCER_UTILS = {
  initialize: rows => rows.map(row => REDUCER_UTILS.initializeRow(row)),
  initializeRow: row => ({
    key: `key-${row.name}`,
    initial: { ...row },
    mutated: { ...row },
    editingStatus: {},
    untrackedFields: {
      productInputValue: row?.productName || null,
      productSelectedValue: null,
      uomInputValue: row?.uomName || null,
      uomSelectedValue: null,
    },
  }),
  calculateTotals: row => {
    row.mutated.extendedPrice = row.mutated.unitPrice * row.mutated.quantity;
    row.mutated.totalTax =
      row.mutated.stateTax +
      row.mutated.countyTax +
      row.mutated.cityTax +
      row.mutated.districtTax +
      row.mutated.salesTax;
    row.mutated.totalPrice = row.mutated.extendedPrice + row.mutated.totalTax;
  },
  calculateRowEditingStatus: row => {
    if (row.editingStatus.isMarkedToCreate || row.editingStatus.isMarkedToDelete) {
      row.editingStatus = pick(row.editingStatus, ['isMarkedToCreate', 'isMarkedToDelete']);
      return;
    }

    row.editingStatus.product = row.initial.productId !== row.mutated.productId;
    row.editingStatus.unitPrice = row.initial.unitPrice !== row.mutated.unitPrice;
    row.editingStatus.quantity = row.initial.quantity !== row.mutated.quantity;
    row.editingStatus.uomCode = row.initial.uomCode !== row.mutated.uomCode;
    row.editingStatus.stateTax = row.initial.stateTax !== row.mutated.stateTax;
    row.editingStatus.countyTax = row.initial.countyTax !== row.mutated.countyTax;
    row.editingStatus.cityTax = row.initial.cityTax !== row.mutated.cityTax;
    row.editingStatus.districtTax = row.initial.districtTax !== row.mutated.districtTax;
    row.editingStatus.totalTax = row.initial.totalTax !== row.mutated.totalTax;
    row.editingStatus.extendedPrice = row.initial.extendedPrice !== row.mutated.extendedPrice;
    row.editingStatus.totalPrice = row.initial.totalPrice !== row.mutated.totalPrice;
  },
};

export const reducerFn = (state, action) => {
  switch (action.type) {
    case REDUCER_ACTIONS.INITIALIZE: {
      const newState = REDUCER_UTILS.initialize(action.payload);

      return newState;
    }

    case REDUCER_ACTIONS.ADD_ITEMS: {
      const { items, products, uoms } = action.payload;

      const newPayload = items.map(item => {
        const newRow = REDUCER_UTILS.initializeRow({
          priceSource: 'Manual',
          productId: item.id,
          productTypeName: item.type,
          productName: item.name,
          productRef: item.crn,
          key: item.crn,
          quantity: 1,
          stateTax: 0,
          countyTax: 0,
          cityTax: 0,
          districtTax: 0,
          salesTax: 0,
          extendedPrice: 0,
          totalTax: 0,
          totalPrice: 0,
          isTaxable: item.taxable === 'YES' ? true : false,
          unitPrice: item.unitPrice ?? 0,
          uomCode: item.uomCode ? item.uomCode.trim() : '',
        });

        const matchProduct = products.find(product => product.crn === newRow.mutated.productRef);
        const matchUom = uoms.find(uom => uom.value === newRow.mutated.uomCode);

        newRow.untrackedFields = {
          ...newRow.untrackedFields,
          productSelectedValue: matchProduct,
          productInputValue: matchProduct.name,
          uomSelectedValue: matchUom,
          uomInputValue: matchUom?.label || null,
        };

        newRow.editingStatus = {
          ...newRow.editingStatus,
          isMarkedToCreate: true,
        };

        REDUCER_UTILS.calculateTotals(newRow);

        return newRow;
      });

      const newData = [...state, ...newPayload];

      return newData;
    }

    case REDUCER_ACTIONS.UPDATE_AFTER_PRODUCTS_LIST_LOADS: {
      const newState = [...state];

      const products = action.payload;

      newState.forEach(row => {
        const matchProduct = products.find(product => product.crn === row.mutated.productRef);

        row.untrackedFields = {
          ...row.untrackedFields,
          productSelectedValue: matchProduct,
        };
      });

      return newState;
    }

    case REDUCER_ACTIONS.UPDATE_AFTER_UOMS_LIST_LOADS: {
      const newState = [...state];

      const uoms = action.payload;

      newState.forEach(row => {
        const matchUom = uoms.find(uom => uom.value === row.mutated.uomCode);

        row.untrackedFields = {
          ...row.untrackedFields,
          uomSelectedValue: matchUom,
          uomInputValue: matchUom?.label || null,
        };
      });

      return newState;
    }

    case REDUCER_ACTIONS.UPDATE_AMOUNT: {
      const newState = [...state];
      const { fieldName, rowKey, value } = action.payload;

      const matchIndex = newState.findIndex(row => row.key === rowKey);
      if (matchIndex !== -1) {
        newState[matchIndex].mutated[fieldName] = value;

        REDUCER_UTILS.calculateTotals(newState[matchIndex]);
        REDUCER_UTILS.calculateRowEditingStatus(newState[matchIndex]);
      }

      return newState;
    }

    case REDUCER_ACTIONS.UPDATE_PRODUCT_VALUE: {
      const newState = [...state];
      const { rowKey, product, uoms } = action.payload;

      const matchIndex = newState.findIndex(row => row.key === rowKey);
      if (matchIndex !== -1) {
        const matchUom = uoms.find(uom => uom.value === product.uomCode);

        newState[matchIndex].untrackedFields = {
          ...newState[matchIndex].untrackedFields,
          productSelectedValue: product,
          productInputValue: product.name,
          uomSelectedValue: matchUom,
          uomInputValue: matchUom?.label || null,
        };

        newState[matchIndex].mutated = {
          ...newState[matchIndex].mutated,
          productId: product.id,
          productTypeName: product.type,
          productName: product.name,
          productRef: product.crn,
          isTaxable: product.taxable === 'YES' ? true : false,
          unitPrice: product.unitPrice ?? 0,
          uomCode: product.uomCode,
        };

        REDUCER_UTILS.calculateTotals(newState[matchIndex]);
        REDUCER_UTILS.calculateRowEditingStatus(newState[matchIndex]);
      }

      return newState;
    }

    case REDUCER_ACTIONS.UPDATE_PRODUCT_INPUT: {
      const newState = [...state];
      const { rowKey, product } = action.payload;

      const matchIndex = newState.findIndex(row => row.key === rowKey);
      if (matchIndex !== -1) {
        newState[matchIndex].untrackedFields = {
          ...newState[matchIndex].untrackedFields,
          productInputValue: product,
        };
      }

      return newState;
    }

    case REDUCER_ACTIONS.UPDATE_UOM_VALUE: {
      const newState = [...state];
      const { rowKey, uom } = action.payload;

      const matchIndex = newState.findIndex(row => row.key === rowKey);
      if (matchIndex !== -1) {
        newState[matchIndex].untrackedFields = {
          ...newState[matchIndex].untrackedFields,
          uomSelectedValue: uom,
          uomInputValue: uom.label,
        };

        newState[matchIndex].mutated = {
          ...newState[matchIndex].mutated,
          uomCode: uom.value,
        };

        REDUCER_UTILS.calculateRowEditingStatus(newState[matchIndex]);
      }

      return newState;
    }

    case REDUCER_ACTIONS.UPDATE_UOM_INPUT: {
      const newState = [...state];
      const { rowKey, uom } = action.payload;

      const matchIndex = newState.findIndex(row => row.key === rowKey);
      if (matchIndex !== -1) {
        newState[matchIndex].untrackedFields = {
          ...newState[matchIndex].untrackedFields,
          uomInputValue: uom,
        };
      }

      return newState;
    }

    case REDUCER_ACTIONS.DELETE_ITEM: {
      const newState = [...state];
      const { rowKey } = action.payload;

      const matchIndex = newState.findIndex(row => row.key === rowKey);
      if (matchIndex !== -1) {
        const matchItem = { ...newState[matchIndex] };

        if (matchItem.editingStatus.isMarkedToCreate) {
          newState.splice(matchIndex, 1);
        } else {
          newState[matchIndex].editingStatus = {
            ...newState[matchIndex].editingStatus,
            isMarkedToDelete: !matchItem.editingStatus.isMarkedToDelete ?? true,
          };
        }
      }

      return newState;
    }

    default:
      return state;
  }
};

export const CONTEXT_INITIAL_STATE = {
  rows: [],
  rowsProductRefs: [],
  products: [],
  uoms: [],
  isLoading: false,
  isProductsLoading: true,
  isUomsLoading: true,
  onAddItems: () => {},
  onDeleteItem: () => {},
  onChangeAmount: () => {},
  onChangeProduct: () => {},
  onChangeProductInput: () => {},
  onChangeUom: () => {},
  onChangeUomInput: () => {},
};
