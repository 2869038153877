import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Autocomplete as MUIAutocomplete, Popper as MUIPopper } from '@mui/material';

export const Autocomplete = styled(MUIAutocomplete)(
  ({ theme }) => css`
    height: 24px;
    width: 100%;
    border-bottom: 1px solid #c9c5ca;

    &.is-edited {
      border-color: ${theme.colorsCA.orange[40]};

      .MuiInputBase-input {
        color: ${theme.colorsCA.orange[40]};
      }

      .MuiButtonBase-root {
        color: ${theme.colorsCA.orange[40]};
      }
    }

    .MuiInputBase-root {
      font-size: 14px;
      padding: 0;
      padding-bottom: 2px;
      color: inherit;

      &.Mui-disabled {
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
        .MuiInputBase-input {
          -webkit-text-fill-color: ${theme.validateMode({
            light: theme.colorsCA.neutral[40],
            dark: theme.colorsCA.neutral[95],
          })};
        }
        .icon {
          color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
        }
        .MuiButtonBase-root {
          color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
        }
        .MuiAutocomplete-endAdornment {
          display: none;
        }
      }

      .MuiInputBase-input {
        padding: 0;

        &::placeholder {
          color: ${theme.validateMode({ light: theme.colorsCA.neutral[50], dark: theme.colorsCA.neutral[90] })};
        }

        &[aria-expanded='false'] ~ .MuiOutlinedInput-notchedOutline {
          border: none;
        }

        &[aria-expanded='true'] ~ .MuiOutlinedInput-notchedOutline {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid ${theme.colorsCA.blue[50]};
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .icon {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[30], dark: theme.colorsCA.neutral[99] })};
    }
  `
);

export const Popper = styled(MUIPopper)(
  ({ theme }) => css`
    box-sizing: content-box;
    max-width: 50%;
    width: auto !important;

    .MuiAutocomplete-paper {
      background: transparent;
      border-radius: 0;
      overflow: visible;
    }

    .MuiAutocomplete-noOptions {
      display: none;
    }

    .MuiAutocomplete-listbox,
    .MuiAutocomplete-noOptions {
      padding: 0;
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
      box-shadow: ${theme.shadowCA(2)};

      .MuiAutocomplete-option {
        gap: 16px;
        justify-content: space-between;
      }
      .MuiStack-root {
        align-items: center;
        gap: 8px;
        width: 100%;
        .MuiBox-root {
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .MuiIconButton-root {
        height: 16px;
        width: 16px;
      }
    }

    .icon {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[90] })};
      font-size: 16px;
    }
  `
);
