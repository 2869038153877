import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { traces } from '../query';

export const useTraces = () => {
  const { entityRef = '9d98a53e-75f9-47bb-8ef9-62e66330d10e' } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['traces', entityRef];

  const ListTraces = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['traces', entityRef, queryString.stringify(queryParams)],
      queryFn: () => traces.listTraces({ entityRef, queryParams }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return { ...queryResult };
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const List = () => 
    useMutation({
      mutationFn: ({ entityRef, queryParams = {} }) =>
        traces.listTraces({
          entityRef,
          queryParams,
        }),
    });

  const Create = () =>
    useMutation({
      mutationFn: ({ data }) => traces.createTrace({ entityRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const Update = () =>
    useMutation({
      mutationFn: ({ data, mixRef }) => traces.updateTrace({ entityRef, mixRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  return { ListTraces, List, Create, Update };
};
