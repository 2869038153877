import React from 'react';
import { useAppContext } from './app-provider/context';

export const useHeaderControls = () => {
  const { headerControls, setHeaderControls } = useAppContext();

  React.useEffect(() => {
    return () => {
      setHeaderControls('');
    };
  }, [setHeaderControls]);

  return { headerControls, setHeaderControls };
};
