import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GridFooterContainer } from '@mui/x-data-grid-pro';

export const GridTableFooter = styled(GridFooterContainer)(
  ({ theme }) => css`
    display: flex;
    background: ${theme.backgroundCA('tableHF')};
    box-shadow: ${theme.validateMode({
      light: '0px -1px 3px 1px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.3)',
      dark: '0px -1px 2px 0px rgba(0, 0, 0, 0.30), 0px -1px 3px 1px rgba(0, 0, 0, 0.15)',
    })};
    border-radius: 0 0 10px 10px;
    border-top: 1px solid ${theme.colorsCA.neutral[50]};
    padding: 0 15px;
    z-index: 1;
    .selection-wrapper,
    .records-wrapper {
      flex-grow: inherit;
      flex-direction: row;
      align-items: center;
      gap: 21px;
    }
    .columns-button {
      padding: 3px 5px;
      font-size: 13px;
      line-height: inherit;
      color: ${theme.colorsCA.blue[50]};
      text-transform: uppercase;
    }
    .refresh-button {
      width: 32px;
      height: 32px;
      background: ${theme.colorsCA.blue[50]};
      color: ${theme.colorsCA.neutral[100]};
    }
    p {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[0] })};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0;
    }
  `
);
