import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { TablePopper, TableColumnsPanel, TablePanel, TableSkeleton, TableColumnMenu } from 'core';
import { useTracesInUseStore, useTracesStore } from 'stores';
import RowDetail from '../TableDetail';
import { TableFooter } from './components';
import { TABLE_CLASSNAMES, useTableColumns, useTableLocaleText, getTogglableColumns } from './config';
import * as S from './style';

const INITIAL_SORT_MODEL = [{ field: 'modifyDate', sort: 'desc' }];

const Table = ({ data, activeColumns }) => {
  const { entityRef } = useParams();
  const { isLoading, onChangeActiveColumns } = useTracesStore();
  const { replaceTracesInUseCrn, tracesInUseCrn } = useTracesInUseStore();
  const [expandedRowCrn, setExpandedRowCrn] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(activeColumns);
  const [sortModel, setSortModel] = useState(INITIAL_SORT_MODEL);
  const localeText = useTableLocaleText();
  const columns = useTableColumns();
  const rows = useMemo(() => (isLoading ? [] : data), [isLoading, data]);
  const expandedRowCrnAsArray = expandedRowCrn ? [expandedRowCrn] : [];
  const footerProps = { totalCount: data?.length || 0 };

  const handleDetailPanelExpandedRowIdsChange = newIds => setExpandedRowCrn(newIds[newIds.length - 1]);

  const handleSortModel = newSortModel => setSortModel(newSortModel);

  const handleColumnsVisibility = newVisibilityModel => {
    setColumnVisibilityModel(newVisibilityModel);
    onChangeActiveColumns(newVisibilityModel);
    setSortModel(prevModel => prevModel.filter(column => !newVisibilityModel[column.field]));
  };

  const handleSelectionModelChange = newSelectedRowsCrn => replaceTracesInUseCrn(newSelectedRowsCrn);

  const handleRowClick = params => setExpandedRowCrn(expandedRowCrn === params.row.crn ? null : params.row.crn);

  const getRowColor = traceLevelId => {
    switch (traceLevelId) {
      case 'ERROR':
        return 'red';
      case 'WARN':
        return 'yellow';
      default:
        return 'transparent';
    }
  };

  const getDetailPanelContent = params => (
    <RowDetail
      dexaRef={params.id}
      className={cn({
        selected: tracesInUseCrn.includes(expandedRowCrn),
        voided: params.row.isVoided,
      })}
      data={
        (params.row.events && Object.keys(params.row.events).map(key => ({ name: key, ...params.row.events[key] }))) ||
        []
      }
    />
  );

  return (
    <S.DataGridTable
      scrollbarSize={6}
      className="main-grid"
      localeText={localeText}
      classes={TABLE_CLASSNAMES}
      disableRowSelectionOnClick
      disableMultipleRowSelection
      disableColumnReorder
      disableColumnFilter
      disableColumnPinning
      disableDensitySelector
      onRowSelectionModelChange={handleSelectionModelChange}
      rowSelectionModel={tracesInUseCrn}
      sortModel={sortModel}
      onSortModelChange={handleSortModel}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnsVisibility}
      slots={{
        basePopper: TablePopper,
        columnMenu: TableColumnMenu,
        columnsPanel: TableColumnsPanel,
        footer: TableFooter,
        loadingOverlay: TableSkeleton,
        panel: TablePanel,
      }}
      slotProps={{
        footer: footerProps,
        loadingOverlay: { rowHeight: 54 },
        columnsPanel: {
          getTogglableColumns,
        },
      }}
      rows={rows}
      getRowId={row => row.crn}
      rowHeight={54}
      rowBuffer={3}
      rowThreshold={3}
      getRowClassName={params => {
        return getRowColor(params.row.traceLevelId);
      }}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => 'auto'}
      detailPanelExpandedRowIds={expandedRowCrnAsArray}
      onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
      columnHeaderHeight={59}
      columnBuffer={1}
      columnThreshold={1}
      columns={columns}
      onRowClick={handleRowClick}
      loading={isLoading}
    />
  );
};

Table.propTypes = {
  activeColumns: PropTypes.object,
  data: PropTypes.array,
};

export default Table;
