import React from 'react';
import { TextField, Button } from '@mui/material';
import { useTracesStore } from 'stores';

const TraceSearchFilters = () => {
  const {
    source,
    sourceType,
    traceType,
    objectType,
    objectId,
    eventDetails,
    onSourceChange,
    onSourceTypeChange,
    onTraceTypeChange,
    onObjectTypeChange,
    onObjectIdChange,
    onEventDetailsChange,
    onSearch,
  } = useTracesStore();

  return (
    <>
      <TextField
        key="traceType"
        variant="standard"
        className="search-input"
        label="Trace Type"
        placeholder="ingress, egress"
        onChange={onTraceTypeChange}
        value={traceType}
      />
      <TextField
        key="source"
        variant="standard"
        className="search-input"
        label="Source"
        placeholder="cs, cb, etc..."
        onChange={onSourceChange}
        value={source}
      />
      <TextField
        key="sourceType"
        variant="standard"
        className="search-input"
        label="Source Type"
        placeholder="customer, location, etc..."
        onChange={onSourceTypeChange}
        value={sourceType}
      />
      <TextField
        key="objectId"
        variant="standard"
        className="search-input"
        label="Object ID"
        placeholder="id or crn"
        onChange={onObjectIdChange}
        value={objectId}
      />
      <TextField
        key="typeId"
        variant="standard"
        className="search-input"
        label="Object Type"
        placeholder=""
        onChange={onObjectTypeChange}
        value={objectType}
      />
      <TextField
        key="details"
        variant="standard"
        className="search-input"
        label="Event Details"
        placeholder=""
        onChange={onEventDetailsChange}
        value={eventDetails}
      />
      <Button className="search-button" variant="contained" color="primary" onClick={onSearch}>
        Search
      </Button>
    </>
  );
};

export default TraceSearchFilters;
