import { useMemo } from 'react';
import { Localization } from 'connex-cds';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from 'core';
import * as S from '../style';

const MenuItem = ({ translation, isSelected, onClick }) => {
  const translateMessage = Localization.useTranslateMessage();

  const labelTranslationId = useMemo(() => {
    const isStr = typeof translation === 'string';

    if (isStr) {
      return translation;
    }

    return translation.id;
  }, [translation]);

  return (
    <S.MenuItem className={cn({ selected: isSelected })} onClick={onClick}>
      {isSelected && <Icon type="check" />}
      {translateMessage(labelTranslationId)}
    </S.MenuItem>
  );
};

MenuItem.propTypes = {
  translation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      onSelectedId: PropTypes.string,
    }),
  ]),
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export { MenuItem };
