import React from 'react';
import * as S from './style';

import { Outlet } from 'react-router-dom';

const Home = () => {

  return (
    <S.HomeWrapper>
      <Outlet />
    </S.HomeWrapper>
  );
};

export default Home;
