import React from 'react';
import { BottomDrawer, RangePicker } from 'core';
import { useTracesStore } from 'stores';

const DateRangeDrawer = () => {
  const { dateRangeFilter, onChangeDateRangeFilter, fetchByDateRange } = useTracesStore();

  const handleAccept = () => fetchByDateRange();

  return (
    <BottomDrawer>
      <RangePicker initialOpen onChange={onChangeDateRangeFilter} value={dateRangeFilter} onAccept={handleAccept} />
    </BottomDrawer>
  );
};

export default DateRangeDrawer;
